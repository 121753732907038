import React from 'react';
import { SalesSummaryDataCard } from './widgets';
import { Grid, Stack } from '@mui/material';
import { CurrencyFormattedText } from '@components/currency';
import ShiftReport from './shift-report';

const AtAGlanceSalesReport = ({ reportData, fromDate, toDate }) => {
  return (
    <Grid container justify="flex-end">
      <Grid item xs={6} md={3} lg={3} justifyContent={'center'}>
        <SalesSummaryDataCard title="Total Orders" data={reportData?.gross_total?.count} />
      </Grid>
      <Grid item xs={6} md={3} lg={2} justifyContent={'center'}>
        <SalesSummaryDataCard title="Sales (w/o VAT)" data={<CurrencyFormattedText variant={'body2'} fontWeight={700} value={reportData?.gross_total.sum ?? 0} />} />
      </Grid>
      <Grid item xs={6} md={3} lg={2} justifyContent={'center'}>
        <SalesSummaryDataCard title="Total VAT" data={<CurrencyFormattedText variant={'body2'} fontWeight={700} value={reportData?.total_tax.sum ?? 0} />} />
      </Grid>
      <Grid item xs={6} md={3} lg={2} justifyContent={'center'}>
        <SalesSummaryDataCard title="Total Tip" data={<CurrencyFormattedText variant={'body2'} fontWeight={700} value={reportData?.tip_total.sum ?? 0} />} />
      </Grid>
      <Grid item xs={12} md={12} lg={3} justifyContent={'center'}>
        <SalesSummaryDataCard title="Gross Sales" data={<CurrencyFormattedText variant={'body2'} fontWeight={700} value={reportData?.total_revenue.sum ?? 0} />} />
      </Grid>
      {/*<Grid item xs={6} md={2} lg={2} justifyContent={'center'}>
        <ShiftReport fromDate={fromDate} toDate={toDate} />
  </Grid>*/}
    </Grid>
  );
};

export default AtAGlanceSalesReport;
