import {
  Box, Button,
} from '@mui/material';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Sections({ children, ...attributes }) {
  return <Box {...attributes}>{children}</Box>;
}

Sections.Header = ({ children, ...attributes }) => (
  <Box {...attributes}>{children}</Box>
);

Sections.Tabs = ({
  tabs, value, setValue, hideIcon,
}) => (
  <Box sx={{
    height: '100%', display: 'flex', flexDirection: 'column', pb: 1,
  }}
  >
    <Sections.TabHeader {...{
      tabs, value, setValue, hideIcon,
    }}
    />
    <Sections.TabPanel {...{ tabs, value }} />
  </Box>
);

Sections.TabHeader = ({
  tabs, value, setValue, hideIcon,
}) => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
    {Array.isArray(tabs) && tabs.map(({
      Icon, label,
    }, key) => (
      <Box key={key}
        sx={{
          flex: 1,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
        }}
      >
        <Button variant="text"
          sx={{
            py: 3,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 0,
            borderBottom: value === key ? '2px solid #1976d2' : 'default',
          }}
          onClick={() => setValue(key)}
          {...a11yProps(key)}
        >
          {!hideIcon && <Icon sx={{ color: '#1976d2', mb: 1 }} />}
          {label}
        </Button>
      </Box>
    ))}
  </Box>
);

Sections.TabPanel = ({ tabs, value }) => {
  const { Panel } = tabs[value];

  return <Panel />;
};
