import { toast } from 'react-toastify';
import translations from './translations.json';

export const colors = {
  primary: '#356ffb',
  secondary: '#D14C3C',
  tertiary: '#CCCCCC',
  lightBlue: '#f2f2ff',
  oddRow: '#f2f2ff',
  evenRow: '#f9fafb'
};

export const AppConstants = {
  STORE: 'STORE',
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DEBUG: 'DEBUG',
  REJETCED: 'REJECTED',
  FULFILLED: 'FULFILLED',
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
  AUTHENTICATED: 'authenticated',
  PRICE: 'price',
  BRAND: 'brand',
  CATEGORY: 'category',
  VAT_GROUP: 'VAT_GROUP',
  KITCHEN_TYPE: 'KITCHEN_TYPE',
  CATEGORY_TYPE: 'CAT_TYPE',
};

export const getTranslation = (key, lang) => {
  try {
    return translations[key][lang];
  } catch (e) {
    return null;
  }
}

export const notify = (type, message) => {
  toast.dismiss();
  toast(message, { type, delay: 0 });
};
