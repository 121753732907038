import { Typography } from '@mui/material';
// import CurrencyFormat from 'react-currency-format';

export function CurrencyFormattedText({ value, currency, variant, fontWeight }) {
  return (
    <Typography variant={variant} fontWeight={fontWeight} noWrap>
      {value ? `${currency ?? 'AED'} ${(value ?? 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : value}
    </Typography>
  );
}

export function CurrencyFormattedTextString(value, currency, noSpace) {
  if (value) {
    return (
      `${currency ?? 'AED'}${noSpace ? '' : ' '}${value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    );
  }
  return value;
}
