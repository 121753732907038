import axios from "axios";
import { createAuthenticationHeaderForApi } from "./groupHandlers";
import { endpoints } from "@src/config/api-config";
import dayjs from "dayjs";

export const listStoreTransactions = async (payload) => {
  try {
    const headers = await createAuthenticationHeaderForApi();
    let uri = `${endpoints.business}/${payload.businessId}/transaction?`
    uri = `${uri}from=${dayjs(payload.fromDate * 1000).tz(payload.timeZone).toISOString()}`
    uri = `${uri}&to=${dayjs(payload.toDate * 1000).tz(payload.timeZone).toISOString()}`
    uri = `${uri}&rows=${payload.rows}`

    if (payload?.paginationToken) {
      uri = `${uri}&paginationToken=${payload?.paginationToken}`
    }
    if (payload?.status && payload?.status !== 'all') {
      uri = `${uri}&status=${payload?.status}`
    }

    const { data } = await axios.get(uri, headers);
    return data;
  } catch (err) {
    throw new Error(err.response.data.error_message);
  }
}

export const getTransactionData = async (transId) => {
  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.get(`${endpoints.business}/tenders/${transId}`, headers);
    return data;
  } catch (err) {
    throw new Error(err.response.data.error_message);
  }
}

export const modifyTransactionData = async (payload) => {
  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.patch(`${endpoints.business}/tenders`, payload, headers);
    return data;
  } catch (err) {
    throw new Error(err.response.data.error_message);
  }
}

export const cancelTransactionTransfer = async (payload) => {
  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.patch(`${endpoints.business}/${payload.businessId}/transaction/modify`, payload, headers);
    return data;
  } catch (err) {
    throw new Error(err.response.data.error_message);
  }
}