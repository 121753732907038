import * as React from 'react';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import Groups from '../pages/groups';
import Products from '../pages/products';
// import Home from '../pages/home';
import Reports from '../pages/reports';
import { useContext } from 'react';
import Loading from '../components/loading';
import SelectStoreDialog from '../components/select-store-dialog';
import { Box } from '@mui/material';
import Sidebar from '../components/sidebar';
import { AppContext } from '../context/app-context';
import ErrorPage from '../pages/error';
import { Auth } from 'aws-amplify';
import { getStoreInfo } from '@src/api';
import { AppConstants, notify } from '@src/config/app-config';
import ItemSalesReport from '@src/pages/reports/itemSalesReport';
import TenderReport from '@src/pages/reports/tenderReport';
import AssociateSalesReport from '@src/pages/reports/associateReport';
import TransactionLookup from '@src/pages/transaction';
import TransactionEdit from '@src/pages/transaction/editTransaction';
import AccountingGroupReport from '@src/pages/reports/acccountingGroupReport';
import SalesReconReport from '@src/pages/reports/salesReconReport';

const AppLayout = () => {
  const {
    setUser, storeId, isApplicationLoading, setIsApplicationLoading, setStoresAvailable, setAllStoresDetails
  } = useContext(AppContext);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setIsApplicationLoading(true);
        const { username } = await Auth.currentUserInfo();
        setUser(username);
        const data = await getStoreInfo(username);
        setStoresAvailable(data.map((item) => item.store_id));
        setAllStoresDetails(data.map((item) => (
          {
            storeId: item.store_id,
            storeName: item.store_name,
            locale: item.locale,
            timeZone: item.timeZone ?? "Asia/Muscat"
          }
        )));
      } catch (e) {
        notify(AppConstants.ERROR, e.msg);
      } finally {
        setIsApplicationLoading(false);
      }
    })();
  }, [setIsApplicationLoading, setStoresAvailable, setUser]);

  if (isApplicationLoading) {
    return <Loading height="100vh" />;
  }

  if (!storeId) {
    return <SelectStoreDialog open={!(open && !storeId)} handleCancel={() => setOpen(false)} />;
  }

  return (
    <Box sx={{
      height: '100vh', display: 'flex', flexDirection: 'row', overflowY: 'hidden',
    }}
    >
      <Sidebar />
      <Outlet />
    </Box>
  );
};

export const routes = [{
  path: '/',
  element: <Reports />,
}, {
  path: '/groups',
  element: <Groups />,
}, {
  path: '/products',
  element: <Products />,
}, {
  path: '/transactions/lookup',
  element: <TransactionLookup />,
}, {
  path: '/transactions/change_tender',
  element: <TransactionEdit />,
}, {
  path: '/dashboard',
  element: <Reports />,
}, {
  path: '/reports/itemsales',
  element: <ItemSalesReport />,
}, {
  path: '/reports/tenders',
  element: <TenderReport />,
},
{
  path: '/reports/salesPostAccounts',
  element: <AccountingGroupReport />,
},
{
  path: '/reports/salesRecon',
  element: <SalesReconReport />,
}, {
  path: '/reports/associates',
  element: <AssociateSalesReport />,
}];

export const router = createBrowserRouter([{
  path: '/',
  element: <AppLayout />,
  children: [...routes],
}, {
  path: '*',
  element: <ErrorPage />,
}]);
