// import { isNaN } from 'formik';
import * as yup from 'yup';

export const groupSchema = yup.object({
  groupId: yup.string().required('Group Id is required'),
  categoryType: yup.string(),
  color: yup.string(),
  sortOrder: yup.number().typeError('Sort Order must be a number'),
  description: yup.string().required('Description is required'),
});

export const categorySchema = yup.object({
  categoryId: yup.string().required('Category Id is required'),
  categoryType: yup.string(),
  color: yup.string(),
  sortOrder: yup.number().typeError('Sort Order must be a number'),
  description: yup.string().required('Description is required'),
});

export const productSchema = yup.object({
  name: yup.string().required('Name is required').max(120, 'Name must be less than 120 characters'),
  sku: yup.string().matches(/^\S+$/, 'Sku/ItemId cannot contain spaces').required('Sku/ItemId is required'),
  // color: yup.string(),
  // size: yup.string().required('Size is required'),
  // uom: yup.string().required('UOM is required'),
  brand: yup.string(),
  kitchenType: yup.array().of(yup.string()),
  salePrice: yup.number().typeError('Invalid input for sale price').required('Sale price is required'),
  listPrice: yup.number().typeError('Invalid input for list price').required('List price is required'),
  description: yup.string().required('Description is required'),
  vatGroup: yup.string(),
  categories: yup.array().of(yup.string()),
});

export const modifierSchema = yup.object({
  modifierId: yup.string(),
  name: yup.string().required('Name is required'),
  price: yup.number().typeError('Invalid input for price').required('Price is required'),
});

export const transactionLineItemSchema = yup.object({
  tenderId: yup.string().required('Tender Type is required'),
  amount: yup.number().required('Amount is required').typeError('Amount can only be a number'),
  tip: yup.number().typeError('Tip can only be a number'),
});
