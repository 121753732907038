import { Box } from '@mui/material';

export default function RaisedBox({
  children, flex, boxShadow = '2px 2px 4px rgba(0, 0, 0, 0.2)',
  display = 'flex', flexDirection = 'column', backgroundColor = '#ffffff',
  sx,
}) {
  return (
    <Box flex={flex}
      sx={{
        backgroundColor,
        width: '100%',
        boxShadow,
        borderRadius: 1,
        display,
        flexDirection,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
