/* eslint-disable no-unused-vars */
import {
  Menu, Window, LocalOffer, Store, ExpandMore, ExpandLess, Assessment, Logout, Category, Sell, PeopleAlt, Payment, Paid, CurrencyExchange, CategoryOutlined, Grading, Search, Pageview, ManageSearchOutlined, ReceiptLongOutlined, ReceiptLongRounded, FindInPageRounded,
} from '@mui/icons-material';
import {
  Box, Stack, Tooltip, Typography,
} from '@mui/material';
import {
  useContext, useState, useEffect, useMemo,
} from 'react';
import { Auth } from 'aws-amplify';
import { AppContext } from '../context/app-context';
import { useNavigate, useLocation } from 'react-router-dom';
import SelectStoreDialog from './select-store-dialog';
import { AppConstants, colors, notify } from '@src/config/app-config';

const getStoreDialog = ({ ...props }) => {
  console.log('Props', props);
  props.setOpen(true);
};

const signOut = async ({ ...props }) => {
  try {
    await props.Auth.signOut();
  } catch (error) {
    notify(AppConstants.ERROR, error.message);
    // console.log('Error signing out:', error);
  } finally {
    localStorage.clear();
    props.clearAppContext();
  }
};

const getSidebarStyles = ({ ...props }) => ({
  backgroundColor: colors.primary,
  height: '100vh',
  width: !props.isSidebarExpanded ? '60px' : '200px',
  color: 'white',
  pt: 1,
  pb: 2,
  pr: !props.isSidebarExpanded ? 0.4 : 1,
  pl: 0.5,
  transition: 'width 0.3s ease',
});

const getItemStyles = (currentPath, path) => ({
  height: '40px',
  px: 1,
  transition: 'transform 0.3s ease-in-out',
  ':hover': { backgroundColor: '#5c8eff', borderRadius: 2 },
  backgroundColor: path && currentPath === path ? '#5c8eff' : '',
  borderRadius: path && currentPath === path ? 2 : '',
  cursor: 'pointer',
});

const routes = [
  {
    icon: Assessment,
    label: 'Reports',
    children: [
      {
        icon: Window,
        label: 'Dashboard',
        path: '/dashboard',
      },
      {
        icon: Sell,
        label: 'Item Sales',
        path: '/reports/itemsales',
      },
      {
        icon: Payment,
        label: 'Sales reports',
        path: '/reports/tenders',
      },
      {
        icon: CategoryOutlined,
        label: 'Account Groups',
        path: '/reports/salesPostAccounts',
      },
      {
        icon: Grading,
        label: 'Sales Reconciliation',
        path: '/reports/salesRecon',
      },
      {
        icon: PeopleAlt,
        label: 'Sales Associate',
        path: '/reports/associates',
      },
    ],
  },
  {
    icon: Window,
    label: 'Groups',
    path: '/groups',
  }, {
    icon: LocalOffer,
    label: 'Products',
    path: '/products',
  }, {
    icon: Paid,
    label: 'Orders',
    path: '/transactions',
    children: [
      {
        icon: FindInPageRounded,
        label: 'Order Lookup',
        path: '/transactions/lookup',
      },
      {
        icon: ReceiptLongRounded,
        label: 'Order Details',
        path: '/transactions/change_tender',
      },
    ]
  },
];

const tools = [{
  icon: Logout,
  label: 'Sign Out',
  method: signOut,
}];

const toolsTop = [{
  icon: Store,
  label: 'Store',
  method: getStoreDialog,
}];

export default function Sidebar() {
  const [currentPath, setCurrentPath] = useState();
  const [open, setOpen] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isSidebarExpanded, setIsSidebarExpanded, clearAppContext, storeId,
  } = useContext(AppContext);

  const routeParams = useMemo(() => ({
    navigate, isSidebarExpanded, currentPath, location,
  }), [currentPath, isSidebarExpanded, location, navigate]);

  const actionParams = useMemo(() => ({
    clearAppContext, isSidebarExpanded, setOpen, storeId,
  }), [clearAppContext, isSidebarExpanded, storeId]);

  useEffect(() => {
    setCurrentPath(location.pathname !== '/' ? location.pathname : '/dashboard');
  }, [location]);

  return (
    <Stack direction="column" sx={getSidebarStyles({ isSidebarExpanded })}>
      <Stack justifyContent="flex-start" sx={{ px: 1 }}><Menu sx={{ fontSize: 32, cursor: 'pointer' }} onClick={() => setIsSidebarExpanded(!isSidebarExpanded)} /></Stack>
      <Stack justifyContent="flex-end" sx={{ px: 0.5 }}>
        {toolsTop.map((item, index) => (<ActionItem key={index} {...{ ...item, ...actionParams }} />))}
      </Stack>
      <Stack flex={1} justifyContent="flex-start" sx={{ pt: 6, px: 0.5 }} spacing={0.5}>
        {routes.map((route, index) => (
          !route.children
            ? <RouteItem key={index} {...{ ...route, ...routeParams }} />
            : <RouteItemWithChildren key={index} {...{ ...route, ...routeParams }} />
        ))}
      </Stack>
      <Stack justifyContent="flex-end" sx={{ px: 0.5 }}>
        {tools.map((item, index) => (<ActionItem key={index} {...{ ...item, ...actionParams }} />))}
      </Stack>
      {open && <SelectStoreDialog open={!(open && !storeId)} handleCancel={() => setOpen(false)} />}
    </Stack>
  );
}

const RouteItem = ({ ...props }) => (
  <Stack direction="column">
    <Item {...props} onClick={() => props.navigate(props.path)} />
  </Stack>
);

const RouteItemWithChildren = ({ ...props }) => {
  const [isChildrenExpanded, setIsChidlrenExpanded] = useState(false);

  return (
    <Stack direction="column" sx={{ marginLeft: '5px' }}>
      <Item {...props}
        onClick={() => setIsChidlrenExpanded(!isChildrenExpanded)}
        isChildrenExpanded={isChildrenExpanded}
        hasChild
      />
      <Stack direction="column" justifyContent="flex-start" sx={{ pt: 0.7, px: 0.7, paddingLeft: '25px' }} spacing={0.7}>
        {props.isSidebarExpanded && isChildrenExpanded && props.children.map((child, index) => (
          <Item
            key={index}
            iconSize={15}
            fontSize="10px"
            marginLeft="10px"
            {...child}
            navigate={props.navigate}
            isSidebarExpanded={props.isSidebarExpanded}
            currentPath={props.currentPath}
            location={props.location}
            onClick={() => props.navigate(child.path)}
          />
        ))}
      </Stack>
    </Stack>

  );
};

const ActionItem = ({ clearAppContext, setOpen, ...props }) => (
  <Stack direction="column">
    <Item {...props} onClick={() => props.method({ Auth, clearAppContext, setOpen })} />
  </Stack>
);

const Item = ({ icon, ...props }) => (
  props.isSidebarExpanded
    ? (<ItemWhenExpanded iconSize={props.iconSize ?? 26} {...props} Icon={icon} />)
    : (<ItemWhenCollapsed iconSize={26} {...props} Icon={icon} />)
);

const ItemWhenCollapsed = ({ Icon, ...props }) => (
  <Tooltip title={props.label} placement="right">
    <Stack direction="row" alignItems="center" sx={() => getItemStyles(props.currentPath, props.path)} onClick={() => props.onClick()}>
      <Icon sx={{ fontSize: props.iconSize }} />
    </Stack>
  </Tooltip>
);

const ItemWhenExpanded = ({ Icon, EndAdornment, ...props }) => (
  <Box sx={{ marginLeft: props.marginLeft ?? '0px' }}>
    <Stack direction="row" spacing={1.5} alignItems="center" sx={() => getItemStyles(props.currentPath, props.path)} onClick={() => props.onClick()}>
      <Icon sx={{ fontSize: props.iconSize }} />
      <Typography sx={{ animation: 'fadein 0.9s', fontSize: props.fontSize ?? '15px', fontWeight: '600' }} variant="subtitle1">
        {props.label === 'Store' && props.storeId ? `${props.label}# ${props.storeId}` : props.label}
      </Typography>
      {props.hasChild && (props.isChildrenExpanded ? <ExpandMore /> : <ExpandLess />)}
    </Stack>
  </Box>
);
