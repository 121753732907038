/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControlLabel, IconButton, List, ListItem, ListItemText, Stack, TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { GroupsContext } from '@src/context/groups-context';
import { AppContext } from '@src/context/app-context';
import {
  handleAddMultipleSubcategoriesToGroup,
  handleModifySingleSubcategoryToGroup, initializeCategoryFields,
} from './methods';
import Loading from '@components/loading';
import { AppConstants, colors } from '@src/config/app-config';
import SearchBar from '@components/searchbar';
import { useFormik } from 'formik';
import { categorySchema } from '@src/utils/schemas';
import { ComboBox } from '@components/dropdown';

function CustomTabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 2, py: 1 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function NewSubCategoryDialog({ isNewCategoryAdded, setIsNewCategoryAdded }) {
  const [value, setValue] = useState(0);
  const {
    categories, selectedGroup, setSelectedGroup, setAllGroups, categoryTypeOptions,
  } = useContext(GroupsContext);
  const { storeId } = useContext(AppContext);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subCategories, setSubcategories] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleCategoryToggle = (category) => () => {
    const currentIndex = selectedCategories.indexOf(category);
    const newSelectedCategories = [...selectedCategories];
    if (currentIndex === -1) newSelectedCategories.push(category);
    else newSelectedCategories.splice(currentIndex, 1);
    setSelectedCategories(newSelectedCategories);
  };

  const saveParams = useMemo(() => ({
    storeId,
    selectedGroup,
    setSelectedGroup,
    setAllGroups,
    setIsNewCategoryAdded,
    setLoading,
    type: AppConstants.ADD,
  }), [selectedGroup, setAllGroups, setIsNewCategoryAdded, setSelectedGroup, storeId]);

  const formik = useFormik({
    initialValues: initializeCategoryFields({}),
    validationSchema: categorySchema,
    onSubmit: (values) => handleModifySingleSubcategoryToGroup({ ...saveParams, category: values }),
    validate: () => ({}),
    enableReinitialze: true,
  });

  const handleSave = async () => {
    console.log('Handle Save Called');
    if (!value) return handleAddMultipleSubcategoriesToGroup({ ...saveParams, selectedCategories });
    return formik.handleSubmit();
  };

  const refreshSection = () => {
    // formik.setValues(initializeCategoryFields({}));
    setSearchValue('');
    setSelectedCategories([]);
  };

  const handleDiscard = () => {
    refreshSection();
    setIsNewCategoryAdded(false);
  };

  useEffect(() => {
    refreshSection();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setSubcategories(categories);
  }, [categories]);

  useEffect(() => {
    if (!searchValue) {
      setSubcategories(categories);
    }
    const filteredCategories = categories?.filter((item) => item.categoryId?.toLowerCase()
      .includes(searchValue?.toLowerCase()));
    setSubcategories(filteredCategories);
  }, [categories, searchValue]);

  return (
    <Dialog open={isNewCategoryAdded} fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20 }}>Add Subcategories</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => handleDiscard()}>
            <Close />
          </IconButton>
        </Box>
        <Box sx={{ px: 3 }}>
          <Tabs value={value} onChange={(event, newValue) => setValue(newValue)} aria-label="basic tabs example">
            <Tab sx={{ textTransform: 'capitalize' }} label="Select Subcategories" {...a11yProps(0)} />
            <Tab sx={{ textTransform: 'capitalize' }} label="Create Manually" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <DialogContent sx={{ pt: 0 }}>
          <Box sx={{ width: '100%', height: '300px' }}>
            <SelectCategoriesPanel {...{
              value, handleCategoryToggle, setSearchValue, searchValue, subCategories, selectedCategories,
            }}
            />
            <CreateCategoryPanel value={value} formik={formik} categoryTypeOptions={categoryTypeOptions} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained"
            onClick={() => handleDiscard()}
            sx={{ backgroundColor: colors.tertiary, ':hover': { backgroundColor: colors.tertiary } }}
          >
            Cancel
          </Button>
          <Button variant="contained"
            sx={{ backgroundColor: colors.primary, ':hover': { backgroundColor: colors.primary } }}
            onClick={() => (loading ? null : handleSave())}
          >
            {!loading ? <>Add</> : <Loading size={24} color="white" />}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export const SelectCategoriesPanel = ({ ...props }) => (
  <CustomTabPanel value={props.value} index={0}>
    <SearchBar searchValue={props.searchValue} setSearchValue={props.setSearchValue} placeholder="Choose categories" />
    <List sx={{ overflow: 'auto' }}>
      {Array.isArray(props.subCategories) && props.subCategories?.map((category, index) => (
        <ListItem key={index} disablePadding>
          <FormControlLabel
            control={(
              <Checkbox
                checked={props.selectedCategories.indexOf(category) !== -1}
                onChange={props.handleCategoryToggle(category)}
                value={category}
              />
             )}
            label={<ListItemText primary={category.categoryId} />}
          />
        </ListItem>
      ))}
    </List>
  </CustomTabPanel>
);

export const CreateCategoryPanel = ({ value, formik, categoryTypeOptions }) => (
  <CustomTabPanel value={value} index={1}>
    <Stack sx={{ pt: 2 }} spacing={2}>
      <TextField sx={{ flex: 1 }} required name="categoryId" label="Category Id" type="text" value={formik.values.categoryId} onChange={formik.handleChange} error={formik.touched.categoryId && Boolean(formik.errors.categoryId)} helperText={formik.touched.categoryId && formik.errors.categoryId} />
      <Stack direction="row" spacing={1}>
        <TextField sx={{ flex: 1 }} name="sortOrder" label="Sort Order" type="text" value={formik.values.sortOrder} onChange={formik.handleChange} error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)} helperText={formik.touched.sortOrder && formik.errors.sortOrder} />
        <TextField sx={{ flex: 1 }} name="color" label="Color" type="text" value={formik.values.color} onChange={formik.handleChange} error={formik.touched.color && Boolean(formik.errors.color)} helperText={formik.touched.color && formik.errors.color} />
      </Stack>
      <ComboBox sx={{ flex: 1 }} options={categoryTypeOptions} name="categoryType" label="Category Type" type="text" value={formik.values.categoryType} onChange={formik.handleChange} error={formik.touched.categoryType && Boolean(formik.errors.categoryType)} helperText={formik.touched.categoryType && formik.errors.categoryType} />
      <TextField sx={{ flex: 1 }} multiline rows={4} required name="description" label="Description" type="text" value={formik.values.description} onChange={formik.handleChange} error={formik.touched.description && Boolean(formik.errors.description)} helperText={formik.touched.description && formik.errors.description} />
    </Stack>
  </CustomTabPanel>
);
