/* eslint-disable no-unused-vars */
import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { AppContext } from './app-context';
import { getDropdownOptions, getLov, getSubCategories } from '@src/api';
import { AppConstants, notify } from '@src/config/app-config';

const ProductsContext = createContext();

const ProductsProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [allProducts, setAllProducts] = useState([]);
  const [setIsAddNewGrpEnabled] = useState(false);
  const [categories, setCategories] = useState([]);
  const [pages, setPages] = useState([{
    pageNo: 0,
    lastEvaluatedKey: '',
  }]);

  const [vatGroupOptions, setVatGroupOptions] = useState([]);
  const [kitchenTypeOptions, setKitchenTypeOptions] = useState([]);
  const { user, storeId } = useContext(AppContext);

  useEffect(() => {
    if (storeId) {
      (async () => {
        try {
          const promiseQueue = [];
          promiseQueue.push(await getSubCategories(storeId));
          promiseQueue.push(await getLov(storeId, AppConstants.VAT_GROUP));
          promiseQueue.push(await getLov(storeId, AppConstants.KITCHEN_TYPE));
          const results = await Promise.allSettled(promiseQueue);
          setCategories(results[0].value.Items);
          setVatGroupOptions(results[1].value);
          setKitchenTypeOptions(results[2].value);
        } catch (err) {
          setCategories([]);
          setVatGroupOptions([]);
          setKitchenTypeOptions([]);
          console.error(err.message);
          // notify(AppConstants.ERROR, err.message);
        }
      })();
    }
  }, [storeId]);

  const invokeAddNewProductEvent = () => {
    setSelectedProduct(null);
    setCategories(null);
  };

  const clearProductsContext = useCallback(() => {
    setSelectedProduct(null);
    setAllProducts(null);
    setIsAddNewGrpEnabled(false);
    setCategories(null);
  }, [setIsAddNewGrpEnabled]);

  useEffect(() => {
    if (!storeId && !user) { clearProductsContext(); }
  }, [clearProductsContext, storeId, user]);

  const contextValue = useMemo(
    () => ({
      pages,
      setPages,
      currentPage,
      setCurrentPage,
      setIsAddNewGrpEnabled,
      selectedProduct,
      setSelectedProduct,
      allProducts,
      setAllProducts,
      invokeAddNewProductEvent,
      categories,
      setCategories,
      vatGroupOptions,
      kitchenTypeOptions,
      clearProductsContext,
    }),
    [allProducts, categories, clearProductsContext, currentPage, kitchenTypeOptions,
      pages, selectedProduct, setIsAddNewGrpEnabled, vatGroupOptions],
  );

  return (
    <ProductsContext.Provider value={contextValue}>
      {children}
    </ProductsContext.Provider>
  );
};

export { ProductsContext, ProductsProvider };
