/* eslint-disable no-unused-vars */
import { CurrencyFormattedText, CurrencyFormattedTextString } from '@components/currency';
import { DownloadForOfflineRounded, Launch } from '@mui/icons-material';
import {
  Box,
  Card, LinearProgress, Stack, Table, TableBody, TableCell, TableRow, Tooltip, Typography,
} from '@mui/material';
import { PureComponent } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';

export function SalesSummaryDataCard(props) {
  const { title, data } = props;
  return (
    <Card
      sx={{
        height: '90px', paddingX: 2, paddingY: 1, margin: 1
      }}
    >
      <Stack direction="column"
        sx={{ paddingX: 2, paddingY: 1 }}
        spacing={0.5}
        justifyContent="center"
      >
        <Typography variant="body2" sx={{ textAlign: 'center', fontWeight: '500' }}>{title}</Typography>
        <Typography variant="h7" sx={{ textAlign: 'center', fontWeight: '700' }}>{data}</Typography>
      </Stack>
    </Card>
  );
}

export class CustomizedAxisTick extends PureComponent {
  render() {
    const {
      x,
      y,
      stroke,
      payload,
    } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={-10} dy={16} fontSize={10} textAnchor="end" stroke={stroke} fill="#000" transform="rotate(-60)">
          {payload.value}
        </text>
      </g>
    );
  }
}

export function SalesSummaryDataCardColored(props) {
  const {
    title, unit, data, color,
  } = props;

  return (
    <Stack direction="column"
      sx={{
        paddingX: 2, paddingY: 1,
      }}
      spacing={0.5}
      justifyContent="center"
    >
      <Typography variant="h5" fontWeight={700} sx={{ textAlign: 'center', fontWeight: '550', height: '30px' }}>{data}</Typography>
      <Typography variant="body2" sx={{ textAlign: 'center', fontWeight: '500' }}>{unit}</Typography>
      <LinearProgress value={100} variant="determinate" sx={{ backgroundColor: 'white', '& .MuiLinearProgress-bar': { backgroundColor: color } }} />
      <Typography variant="h7" sx={{ textAlign: 'center', fontWeight: '500' }}>{title}</Typography>
    </Stack>
  );
}

export function SummaryDataCardTopEntries(props) {
  const {
    title, data: reportData, valueFormatter, valueField, detailReportId, detailReportIpath, detailReportLabel
  } = props;

  const data = reportData.buckets && reportData.buckets.length > 0 ? reportData.buckets.map((bucket) => ({
    ...bucket,
    color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    displayValue: valueField === 'DOC_COUNT' ? bucket.doc_count : bucket.total_value.value,
  })).sort((a, b) => {
    if (a.displayValue < b.displayValue) { return 1; }
    if (a.displayValue > b.displayValue) { return -1; }
    return 0;
  }) : [];

  const csvData = data.map((dataPoint) => ({
    id: dataPoint.key,
    value: dataPoint.displayValue,
  }));

  const maxValue = reportData.buckets && reportData.buckets.length > 0 ? (data.reduce((prev, current) => (
    (prev.displayValue > current.displayValue) ? prev : current))).displayValue : 0;

  return (
    <Card>
      <Box minHeight={430}>
        <Stack direction="column"
          sx={{
            paddingX: 2, paddingY: 3,
          }}
          spacing={0.5}
          justifyContent="space-between"
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: '500' }}>{title}</Typography>
            {detailReportId && csvData.length > 0 ? (
              <CSVLink
                style={{ textDecoration: 'none', color: '#333333' }}
                data={csvData}
                key={detailReportId}
                filename={`${detailReportId}.csv`}
                target="_blank"
              >
                <Tooltip title="Download Data">
                  <DownloadForOfflineRounded />
                </Tooltip>
              </CSVLink>
            ) : <div />}
          </Stack>
          <Box height={10} />
          <Box minHeight={290}>
            <Table >
              <TableBody>
                {data.length > 0 ? data.slice(0, 10).map((itemData) => (
                  <TableRow key={itemData.key}>
                    <TableCell width={50} sx={{ padding: '2px 10px', borderBottom: 'none' }}>
                      <Typography noWrap sx={{ fontSize: 12, textAlign: 'right', fontWeight: '550' }}>{itemData.key}</Typography>
                    </TableCell>
                    <TableCell sx={{ padding: '2px 0px' }}>
                      <Box>
                        <Box
                          display={'inline-block'}
                          width={`${(itemData.displayValue * 100) / maxValue}%`}>
                          <LinearProgress
                            thickness={10}
                            value={100}
                            variant="determinate"
                            sx={{
                              height: '8px', borderRadius: '5px', backgroundColor: 'white', '& .MuiLinearProgress-bar': { backgroundColor: itemData.color },
                            }}
                          />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell width={50} sx={{ padding: '2px 10px' }}>
                      <Typography sx={{ fontSize: 12, textAlign: 'right', fontWeight: '550', display: 'inline-block' }} noWrap>
                        {valueFormatter === 'CURRENCY' ? CurrencyFormattedTextString(itemData.displayValue) : itemData.displayValue}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell>
                      <Stack minHeight={210} direction="column" justifyContent="center">
                        <Typography variant="body2" sx={{ textAlign: 'center', fontWeight: '550' }}>No data available</Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
          {detailReportIpath ? (
            <Link to={detailReportIpath} style={{ textDecoration: 'none', color: 'black' }} target='_blank'>
              <Stack direction="row" justifyContent="center">
                <Launch style={{ margin: '4px' }} />
                <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: '500' }}>{detailReportLabel ?? 'Detailed Report'}</Typography>
              </Stack>
            </Link>
          ) : (<div />)}
        </Stack>
      </Box>
    </Card>
  );
}


