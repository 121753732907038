/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import ListBox from '../../components/list';
import {
  Add, ArrowBack, ArrowForward, SkipPrevious, Sort,
} from '@mui/icons-material';
import { ProductsContext } from '../../context/products-context';
import { AppContext } from '../../context/app-context';
import { intializeProductsPage, sortProducts } from './methods';
import Loading from '@components/loading';
import {
  Box,
  Button,
  Chip,
  Pagination,
  PaginationItem,
  Stack, TextField, Typography,
} from '@mui/material';
import { AppConstants, colors } from '@src/config/app-config';
import DialogBox from '@components/dialog-box';
import ProductDetails, { ProductDetailsPanel } from './details';
import { useFormik } from 'formik';
import { productSchema } from '@src/utils/schemas';
import { ComboBox } from '@components/dropdown';

export default function ProductsList({ ...attributes }) {
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const { storeId } = useContext(AppContext);
  const {
    currentPage, setCurrentPage, allProducts, setAllProducts, selectedProduct,
    setSelectedProduct, totalPages, setTotalPages, pages, setPages,
  } = useContext(ProductsContext);
  const [products, setProducts] = useState([]);
  const [sortParam, setSortParam] = useState('');
  const [addProductViewEnabled, setAddProductViewEnabled] = useState(false);
  const [prevSelected, setPrevSelected] = useState();
  // const [page, setPage] = useState(1);

  useEffect(() => {
    (async () => {
      await intializeProductsPage({
        pages,
        setPages,
        storeId,
        currentPage,
        setTotalPages,
        setLoading,
        setProducts,
        setAllProducts,
        setSelectedProduct,
      });
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, storeId]);

  useEffect(() => {
    if (!searchValue) {
      setProducts(allProducts);
      return;
    }
    setProducts((prev) => {
      const filteredProducts = prev.filter((item) => item?.name?.toLowerCase().includes(searchValue.toLowerCase())
        || item?.brand?.toLowerCase().includes(searchValue.toLowerCase())
        || item?.sku?.toLowerCase().includes(searchValue.toLowerCase()));
      return filteredProducts;
    });
  }, [allProducts, searchValue]);

  useEffect(() => {
    setProducts(allProducts);
  }, [allProducts]);

  useEffect(() => {
    if (selectedProduct) setPrevSelected(selectedProduct);
  }, [selectedProduct]);

  const addProduct = () => {
    setAddProductViewEnabled(true);
    setSelectedProduct(null);
  };

  const handleClose = () => {
    setAddProductViewEnabled(false);
    setSelectedProduct(prevSelected);
  };

  const handlePageChange = (e, newValue) => {
    setCurrentPage((prev) => newValue);
  };

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <Box {...attributes}>
      <ListBox sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <ListBox.HeaderWithIconAndSearchBar
          title="Products"
          placeholder="Search Products"
          Icon={Add}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onIconClick={addProduct}
        />
        {loading && <Loading flex={1} />}
        {!loading
          && (
          <Stack flex={1} direction="column" alignContent="space-between" sx={{ overflow: 'hidden' }}>
            <Stack direction="row" sx={{ pb: 1, px: 1 }} spacing={0.75}>
              <Chip icon={<Sort />} size="small" label="Price Low to High" sx={{ borderRadius: 1.5, cursor: 'pointer' }} onClick={() => sortProducts({ products, setProducts, param: AppConstants.PRICE })} />
              <Chip icon={<Sort />} size="small" label="Brand" sx={{ borderRadius: 1.5, cursor: 'pointer' }} onClick={() => sortProducts({ products, setProducts, param: AppConstants.BRAND })} />
              <Chip icon={<Sort />} size="small" label="Category" sx={{ borderRadius: 1.5, cursor: 'pointer' }} onClick={() => sortProducts({ products, setProducts, param: AppConstants.CATEGORY })} />
            </Stack>
            <ListBox.List
              flex={1}
              spacing={1}
              list={products}
              sx={{ overflowY: 'auto', borderRadius: 1 }}
            >
              {({ item, key }) => (
                <ProductCard key={key} product={item} />
              )}
            </ListBox.List>
            <Stack direction="row" justifyContent="space-between" p="2px 10px">
              <Button onClick={() => handlePrev()} sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                <ArrowBack sx={{ fontSize: 20 }} />
                <Typography variant="subtitle2" sx={{ pl: 1 }}>Prev</Typography>
              </Button>
              <Button onClick={() => setCurrentPage(currentPage + 1)} sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                <Typography sx={{ pr: 1 }} variant="subtitle2">Next</Typography>
                <ArrowForward sx={{ fontSize: 20 }} />
              </Button>
            </Stack>
          </Stack>
          )}
      </ListBox>
      {addProductViewEnabled && (
      <NewProductDialog open={addProductViewEnabled}
        handleClose={handleClose}
      />
      )}
    </Box>
  );
}

const NewProductDialog = ({ open, handleClose }) => (
  <DialogBox open={open} title="Create New Product" handleClose={handleClose}>
    <ProductDetailsPanel {...{ open, handleClose }} />
  </DialogBox>
);

const ProductCard = ({ product }) => {
  const {
    sku, name, brand, categories, salePrice, listPrice,
  } = product;

  const { selectedProduct, setSelectedProduct } = useContext(ProductsContext);

  const handleClick = (item) => {
    setSelectedProduct(item);
  };

  return (
    <Stack
      sx={{
        cursor: 'pointer',
        ':hover': { backgroundColor: colors.lightBlue },
        backgroundColor: '#eeecfc',
        borderLeft: product?.sku === selectedProduct?.sku ? '6px solid blue' : '6px solid transparent',
        borderRadius: product?.sku === selectedProduct?.sku ? 1 : '',
      }}
      direction="row"
      alignContent="center"
      justifyContent="space-between"
      onClick={() => handleClick(product)}
    >
      <Stack direction="column" sx={{ py: 1, px: 2 }}>
        <Typography variant="subtitle-1">{name}</Typography>
        <Typography variant="caption">{sku}</Typography>
        <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
          {brand && <Chip size="small" label={brand} sx={{ borderRadius: 1 }} />}
          {Array.isArray(categories) && categories.length > 0 && <Chip size="small" label={categories[0]} sx={{ borderRadius: 1 }} />}
        </Stack>
      </Stack>
      <Stack direction="column" sx={{ py: 1, px: 2 }}>
        <Typography variant="caption">{salePrice}</Typography>
        <Typography variant="caption" sx={{ textDecoration: 'line-through', textDecorationThickness: '1.10px', color: 'gray' }}>{listPrice}</Typography>
      </Stack>
    </Stack>
  );
};
