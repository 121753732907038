import { Box } from '@mui/material';
// import { ProductsProvider } from '../../context/products-context';
import ProductsList from './list';
import ProductDetails from './details';
import { ProductsProvider } from '@src/context/products-context';

const styles = {
  container: {
    display: 'flex',
    backgroundColor: '#e2e2e2',
    width: '100%',
    height: '100%',
    px: 2,
    py: 1,
  },
  item: {
    backgroundColor: 'white',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: 1,
  },
};

export default function Products() {
  return (
    <ProductsProvider>
      <Box sx={styles.container}>
        <ProductsList sx={{
          ...styles.item,
          flex: 1 / 3,
        }}
        />
        <ProductDetails sx={{
          ...styles.item,
          flex: 2 / 3,
          ml: 2,
        }}
        />
      </Box>
    </ProductsProvider>
  );
}
