/* eslint-disable no-unused-vars */
import { ArrowRightAltRounded, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  Card,
  Typography,
  Grid,
  FormHelperText,
  Snackbar,
  Alert,
} from '@mui/material';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Component } from 'react';

export const DatefilterOptions = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THISWEEK: 'This Week',
  THISMONTH: 'This Month',
  CUSTOMDATE: 'Custom Date Range',
};

export function getFromDateFromFilter(filter, timeZone) {
  var fromDate = dayjs().tz(timeZone);
  fromDate = fromDate.startOf('day');
  switch (filter) {
    case 'YESTERDAY':
      fromDate = fromDate.subtract(1, 'day');
      break;
    case 'THISWEEK':
      fromDate = fromDate.startOf('week');
      break;
    case 'THISMONTH':
      fromDate = fromDate.startOf('month');
      break;
    default:
      break;
  }
  return fromDate.unix();
}

export function getToDateFromFilter(filter, timeZone) {
  var toDate = dayjs().tz(timeZone);
  switch (filter) {
    case 'YESTERDAY':
      toDate = toDate.startOf('day').subtract(1, 'second');
      break;
    default:
      break;
  }
  return toDate.unix();
}

export class DateFilterWidget extends Component {
  constructor(props) {
    super(props);
    const { fromDate, toDate, storeDetails } = props;
    this.timeZone = storeDetails.timeZone;
    const dateFilter = fromDate == null || toDate == null;
    this.dateFilterFinal = dateFilter ? 'TODAY' : 'CUSTOMDATE';
    this.selectedToDate = dateFilter ? getToDateFromFilter(this.dateFilterFinal, this.timeZone) : toDate;
    this.selectedFromDate = dateFilter ? getFromDateFromFilter(this.dateFilterFinal, this.timeZone) : fromDate;
    this.state = {
      dateSelectType: this.dateFilterFinal,
      dateError: false,
      storeDetails
    };
  }

  setDateFilter(event) {
    const { fetchReports } = this.props;
    this.setState({
      dateSelectType: event.target.value,
    });
    this.dateFilterFinal = event.target.value;
    if (event.target.value !== 'CUSTOMDATE') {
      this.selectedFromDate = getFromDateFromFilter(event.target.value, this.timeZone);
      this.selectedToDate = getToDateFromFilter(event.target.value, this.timeZone);
      fetchReports(this.selectedFromDate, this.selectedToDate, event.target.value);
    } else {
      this.selectedFromDate = dayjs(this.selectedFromDate * 1000).tz(this.timeZone).hour(0).minute(0).second(0).unix();
      this.selectedToDate = (this.selectedToDate * 1000) > dayjs().unix() ? dayjs().unix() :
        dayjs(this.selectedToDate * 1000).tz(this.timeZone).hour(24).minute(59).second(59).unix();
    }
  }

  setFromDate(value) {
    this.selectedFromDate = value.tz(this.timeZone).startOf('day').unix();
  }

  setToDate(value) {
    value = value.unix() > dayjs().unix() ? dayjs() : value.tz(this.timeZone).hour(23).minute(59).second(59);
    this.selectedToDate = value.unix();
  }

  getFromDate() {
    //return formatISO(new Date(this.selectedFromDate * 1000))
    return dayjs.utc(this.selectedFromDate * 1000);
  }

  getToDate() {
    //return formatISO(new Date(this.selectedToDate * 1000))
    return dayjs.utc(this.selectedToDate * 1000);
  }

  loadReports() {
    const { fetchReports } = this.props;
    if (this.selectedFromDate > this.selectedToDate) {
      this.setState({
        dateError: true
      })
      return;
    }
    fetchReports(this.selectedFromDate, this.selectedToDate, this.dateFilterFinal);
  }

  componentDidUpdate(prevProps) {
    if (this.props.storeDetails.storeId !== prevProps.storeDetails.storeId) {
      const { storeDetails } = this.props;
      this.setState({
        storeDetails,
      });
    }
  }

  handleAlertClose() {
    this.setState({
      dateError: false
    })
  }

  render() {
    const dateOptions = Object.keys(DatefilterOptions);
    const { dateSelectType, dateError, storeDetails } = this.state;
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} timeZone={this.timeZone}>
        <Grid container spacing={1} padding={1}>
          <Grid item xs={12} md={4} justifyContent={'center'} alignItems="center">
            <FormHelperText></FormHelperText>
            <Typography variant="h6" align='center' padding={1}>{storeDetails.storeId}  {storeDetails.storeName}</Typography>
          </Grid>
          <Grid item xs={12} md={2} justifyContent={'center'}>
            <FormHelperText>Report Date Options</FormHelperText>
            <Select
              size="small"
              fullWidth
              id="date-range-type-select"
              value={dateSelectType}
              label="Date Filter Type"
              width={200}
              onChange={(event) => this.setDateFilter(event)}
            >
              {dateOptions.map(
                (option) => (
                  <MenuItem key={option} value={option}>
                    {DatefilterOptions[option]}
                  </MenuItem>
                ),
              )}
            </Select>
          </Grid>
          <Grid item xs={12} md={6} justifyContent={'center'}>
            <Stack direction="row" justifyContent="center">
              <Stack direction="row" justifyContent="space-between">
                <Box justifyContent={'center'}>
                  <FormHelperText>From Date ({this.timeZone})</FormHelperText>
                  <DatePicker
                    slotProps={{ textField: { size: 'small' } }}
                    value={this.getFromDate()}
                    onChange={(newValue) => this.setFromDate(newValue)}
                    disabled={dateSelectType !== 'CUSTOMDATE'}
                    maxDate={dayjs().subtract(1, 'second')}
                    timezone={this.timeZone}
                  />
                </Box>
                <Box paddingTop={4}>
                  <ArrowRightAltRounded style={{ margin: 'auto 5px' }} />
                </Box>
                <Box justifyContent={'center'}>
                  <FormHelperText>To Date ({this.timeZone})</FormHelperText>
                  <DatePicker
                    slotProps={{ textField: { size: 'small' } }}
                    value={this.getToDate()}
                    onChange={(newValue) => this.setToDate(newValue)}
                    disabled={dateSelectType !== 'CUSTOMDATE'}
                    maxDate={dayjs()}
                    timezone={this.timeZone}
                  />
                </Box>
              </Stack>
              <Box>
                <FormHelperText> </FormHelperText>
                {dateSelectType === 'CUSTOMDATE' ?
                  <Button sx={{ marginLeft: '10px' }} variant="contained" onClick={() => { this.loadReports(); }}><Search /></Button> : <></>}
              </Box>
            </Stack>
          </Grid>
        </Grid>
        {dateError ?
          <Snackbar
            open={open}
            autoHideDuration={4000}
            onClose={() => this.handleAlertClose()}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert variant="filled" onClose={() => this.handleAlertClose()} severity="error" sx={{ width: '100%' }}>
              Invalid date range provided. Dates cannot be in future and "From Date" cannot be after "To Date".
            </Alert>
          </Snackbar>
          : <></>}
      </LocalizationProvider>
    );
  }
}
