import {
  Fragment, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Card, Typography, Button, Stack, Chip, Box, TextField, IconButton,
} from '@mui/material';
import { GroupsContext } from '../../context/groups-context';
import { Delete, Edit } from '@mui/icons-material';
import { AppContext } from '../../context/app-context';
import { ComboBox } from '../../components/dropdown';
import {
  handleDeleteSubcategoryFromGroup, handleModifySingleSubcategoryToGroup,
  initializeCategoryFields,
} from './methods';
import { AppConstants, colors } from '../../config/app-config';
import Loading from '../../components/loading';
import { useFormik } from 'formik';
import { categorySchema } from '@src/utils/schemas';
import { modifyString } from '@src/utils/methods';

export default function SubCategoryCard({
  subCategory, setIsNewCategoryAdded, index, expandedIndex, setExpandedIndex,
}) {
  // const [selected, setSelected] = useState(false);
  const { storeId } = useContext(AppContext);
  const {
    selectedGroup, setSelectedGroup, setAllGroups, categoryTypeOptions,
  } = useContext(GroupsContext);
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleEdit = (value, idx) => {
    setIsEditable(value);
    setExpandedIndex(idx);
  };

  useEffect(() => {
    if (expandedIndex === index && !isEditable) { setExpandedIndex(null); }
    if (expandedIndex !== index) { setIsEditable(false); }
  }, [expandedIndex, index, isEditable, setExpandedIndex]);

  const saveParams = useMemo(() => ({
    storeId,
    selectedGroup,
    setSelectedGroup,
    setAllGroups,
    setIsNewCategoryAdded,
    setLoading,
    setIsEditable,
    type: AppConstants.UPDATE,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [selectedGroup, setAllGroups, setIsNewCategoryAdded, setSelectedGroup, storeId]);

  const deleteParams = useMemo(() => ({
    storeId,
    subCategory,
    selectedGroup,
    setSelectedGroup,
    setLoading,
    setAllGroups,
  }), [selectedGroup, setAllGroups, setSelectedGroup, storeId, subCategory]);

  const formik = useFormik({
    initialValues: initializeCategoryFields(subCategory),
    validationSchema: categorySchema,
    onSubmit: (values) => handleModifySingleSubcategoryToGroup({ category: values, ...saveParams }),
    validate: () => ({}),
    enableReinitialze: true,
  });

  const handleDiscard = () => {
    formik.setValues(initializeCategoryFields(subCategory));
    setIsEditable(false);
  };

  useEffect(() => {
    formik.setValues(initializeCategoryFields(subCategory));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory]);

  return (
    <Card sx={{
      padding: '20px 15px',
      backgroundColor: '#ffffff',
      cursor: 'pointer',
      flex: 1,
      boxShadow: 0,
      borderRadius: 2,
    }}
    >

      <form onSubmit={formik.handleSubmit}>
        {isEditable
        && (
          <>
            <Stack spacing={2}>
              <Stack direction="row" spacing={1}>
                <TextField sx={{ flex: 1 }} InputProps={{ readOnly: !!subCategory }} required name="categoryId" label="Category Id" type="text" value={formik.values.categoryId} onChange={formik.handleChange} error={formik.touched.categoryId && Boolean(formik.errors.categoryId)} helperText={formik.touched.categoryId && formik.errors.categoryId} />
                <ComboBox sx={{ flex: 1 }} options={categoryTypeOptions} name="categoryType" label="Category Type" type="text" value={formik.values.categoryType} onChange={formik.handleChange} error={formik.touched.categoryType && Boolean(formik.errors.categoryType)} helperText={formik.touched.categoryType && formik.errors.categoryType} />
              </Stack>
              <Stack direction="row" spacing={1}>
                <TextField sx={{ flex: 1 }} name="sortOrder" label="Sort Order" type="text" value={formik.values.sortOrder} onChange={formik.handleChange} error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)} helperText={formik.touched.sortOrder && formik.errors.sortOrder} />
                <TextField sx={{ flex: 1 }} name="color" label="Color" type="text" value={formik.values.color} onChange={formik.handleChange} error={formik.touched.color && Boolean(formik.errors.color)} helperText={formik.touched.color && formik.errors.color} />
              </Stack>
              <TextField sx={{ flex: 1 }} multiline rows={4} required name="description" label="Description" type="text" value={formik.values.description} onChange={formik.handleChange} error={formik.touched.description && Boolean(formik.errors.description)} helperText={formik.touched.description && formik.errors.description} />
            </Stack>
            <Stack direction="row" alignContent="center" sx={{ pt: 2 }}>
              <Box width="60px">
                {loading ? <Loading size={24} color={colors.primary} />
                  : (<Button onClick={() => formik.handleSubmit()}>Save</Button>)}
              </Box>
              <Button sx={{ color: colors.secondary }} onClick={handleDiscard}>Discard</Button>
            </Stack>
          </>
        )}

        {!isEditable && (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Box>
            <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>{modifyString(formik.values.categoryId?.toLowerCase(), 12)}</Typography>
            {formik.values.categoryType ? <Typography variant="subtitle2">{modifyString(formik.values.categoryType, 12)}</Typography> : <Box />}
            <Typography variant="subtitle2">{modifyString(formik.values.color || '[No-color]', 12)}</Typography>
            <Typography variant="subtitle2">{modifyString(formik.values.description, 12)}</Typography>
          </Box>
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Chip variant="outlined" label={formik.values.sortOrder || 0} sx={{ mr: 0.5 }} />
            <IconButton onClick={() => toggleEdit(true, index)}><Edit sx={{ fontSize: 20, color: '#356ffb' }} /></IconButton>
            {loading ? <Loading size={24} color={colors.secondary} />
              : (
                <IconButton onClick={() => handleDeleteSubcategoryFromGroup(deleteParams)}>
                  <Delete sx={{ color: colors.secondary, fontSize: 20 }} />
                </IconButton>
              )}
          </Stack>
        </Stack>
        )}
      </form>
    </Card>
  );
}
