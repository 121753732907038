import { Component, useContext } from 'react';
import { DateFilterWidget, getFromDateFromFilter, getToDateFromFilter } from './dateFilter';
import { AppContext } from '@context/app-context';
import { Box, Card, CircularProgress, Stack, Typography } from '@mui/material';
import { getSalesSummary } from '@src/api';
import { SummaryDataCardTopEntries } from './widgets';
import { useSearchParams } from 'react-router-dom';

export default function AssociateSalesReport() {
  const { storeId, allStoresDetails } = useContext(AppContext);
  const storeDetails = allStoresDetails.find(x => x.storeId === storeId) ?? {
    storeId,
    storeName: storeId,
    locale: 'en_US',
    timeZone: 'Asia/Muscat'
  }
  const [searchParams] = useSearchParams();
  const filterParams = Object.fromEntries([...searchParams]);
  return (
    <AssociateSalesReportView
      storeId={storeId}
      storeDetails={storeDetails}
      currentFromDate={filterParams.currentFromDate}
      currentToDate={filterParams.currentToDate}
    />
  );
}
class AssociateSalesReportView extends Component {
  constructor(props) {
    super(props);
    const { storeId, storeDetails } = this.props;
    this.state = {
      storeId,
      reportData: null,
      storeDetails,
      status: 'LOADING',
    };
    this.loadReports = this.loadReports.bind(this);
  }

  componentDidMount() {
    const { currentFromDate, currentToDate } = this.props;
    if (currentFromDate == null || currentToDate == null) {
      this.loadReports(getFromDateFromFilter('TODAY'), getToDateFromFilter('TODAY'));
    } else {
      this.loadReports(currentFromDate, currentToDate,
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.storeId !== prevProps.storeId) {
      this.componentDidMount();
    }
  }

  loadReports(fromDate, toDate) {
    const { storeId } = this.props;
    console.log('Loading reports');
    this.setState({
      reportData: null,
      status: 'LOADING',
    });
    getSalesSummary(fromDate, toDate, storeId).then((data) => {
      this.setState({
        reportData: data,
        storeId,
        status: 'LOADED',
      });
    }).catch((err) => {
      console.log('Error while fetching Associate report', err.message);
      this.setState({
        reportData: null,
        storeId,
        status: 'ERROR',
      });
    });
  }

  render() {
    const { reportData, status } = this.state;
    const { currentFromDate, currentToDate, storeDetails } = this.props;
    return (
      <Box
        sx={{
          backgroundColor: '#e2e2e2',
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Stack direction="column" sx={{ height: '100%', paddingX: 1, paddingY: 0 }} spacing={1}>
          <Box>
            <Card>
              <DateFilterWidget
                fetchReports={this.loadReports}
                fromDate={currentFromDate}
                toDate={currentToDate}
                storeDetails={storeDetails}
              />
            </Card>
          </Box>
          {status === 'LOADING' ?
            (<Stack direction="row" sx={{ height: '100%', width: '100%', paddingX: 2, paddingY: 1 }} spacing={2} justifyContent="center">
              <CircularProgress />
            </Stack>)
            : status === 'ERROR' ?
              (<Stack direction="row" justifyContent="center">Opps! We encountered an issue while fetching the report data!!</Stack >)
              : reportData?.top_associate ?
                (<SummaryDataCardTopEntries title="Top Associates" valueField="DOC_COUNT" data={reportData.top_associate} detailReportId="associates" />)
                : (<div>No Data Available</div>)
          }
        </Stack>
      </Box>
    );
  }
}
