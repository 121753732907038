import DialogBox from "@components/dialog-box";
import Dropdown, { ComboBox } from "@components/dropdown";
import { AppContext } from "@context/app-context";
import { Box, Button, Stack, TextField } from "@mui/material";
import { getAvailableTenderTypes } from "@src/api";
import { AppConstants, notify } from "@src/config/app-config";
import { transactionLineItemSchema } from "@src/utils/schemas";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { nanoid } from 'nanoid';
import { number, subtract, bignumber } from 'mathjs';

export default function AddTenderLineDialog({ open, handleClose, transDetails, setTransDetails, paymentLineItems, setPaymentLineItems}){

    const { storeId, tenderTypesAvailable, setTenderTypesAvailable } = useContext(AppContext);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        try{
            setLoading(true);
            let changeAmount = 0;
            if(values.tenderId.toLowerCase() === 'cash' && values.receivedAmount){
                changeAmount = subtract(bignumber(values.receivedAmount), bignumber(values.amount));
                if(number(values.amount) >  number(values.receivedAmount) ){
                    throw new Error('Received amount must be greater than amount')
                }
            }
            const newPaymentLine = {
                id: nanoid(64),
                authCode: null,
                beginDate : new Date().getTime() * 1000,
                endDate: new Date().getTime() * 1000,
                currencyId: 'AED',
                isVoid: false,
                paymentSeq: transDetails?.paymentLineItems.length + 1,
                tenderStatusCode: 'CNF',
                token: null,
                transId: transDetails?.transId,
                ...values,
                amount : number(values.amount),
                tip: number(values.tip),
                changeAmount : number(changeAmount),
                receivedAmount: number(values.receivedAmount),
            }


            if(newPaymentLine.tip > newPaymentLine.amount){
                throw new Error("Tip cannot be greater than amount");
            }
            setPaymentLineItems(prev => [...prev, newPaymentLine]);
            handleDiscard();
        }catch(err){
            console.log("Error: ", err)
            notify(AppConstants.ERROR, err.message);
        }finally{
            setLoading(false);
        }
    }

    const initialValues = {
        tenderId: '',
        amount: '',
        tip: '',
        receivedAmount: '',
      };
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: transactionLineItemSchema,
        onSubmit: async (values) => {
          await handleSubmit(values);
        },
        enableReinitialize: true,
      });

    const handleDiscard = () => {
        formik.setValues(initialValues);
        formik.setErrors({});
        formik.setTouched({});
        handleClose(false);
    }

    return (
        <DialogBox
            title='New Transaction Line'
            open={open}
            handleClose={handleClose}
        >
            <form>
                <Stack direction='column' sx={{p:2}} spacing={1}>
                <ComboBox sx={{ flex: 1 }} options={tenderTypesAvailable} name="tenderId" label="Tender Type" type="text" value={formik.values.tenderId} onChange={formik.handleChange} error={formik.touched.tenderId && Boolean(formik.errors.tenderId)} helperText={formik.touched.tenderId && formik.errors.tenderId} />
                <Stack direction='row' justifyContent='space-between' spacing={1}>
                    <TextField sx={{flex:1}} required name="amount" label="Amount" type="text" value={formik.values.amount} onChange={formik.handleChange} error={formik.touched.amount && Boolean(formik.errors.amount)} helperText={formik.touched.amount && formik.errors.amount} />
                    <TextField sx={{flex:1}} name="tip" label="Tip" type="text" value={formik.values.tip} onChange={formik.handleChange} error={formik.touched.tip && Boolean(formik.errors.tip)} helperText={formik.touched.tip && formik.errors.tip} />
                </Stack>
                {formik.values.tenderId?.toLocaleLowerCase() === 'cash'  && 
                    <TextField sx={{flex:1}} name="receivedAmount" label="Recieved Amount" type="text" value={formik.values.receivedAmount} onChange={formik.handleChange} error={formik.touched.receivedAmount && Boolean(formik.errors.receivedAmount)} helperText={formik.touched.receivedAmount && formik.errors.receivedAmount} />
                }
                <Stack direction='row' spacing={1} sx={{py:1}}>
                    <Box sx={{flex:1}} />
                    <Button variant='contained' color="inherit" onClick={() => handleDiscard()}>Discard</Button>
                    <Button variant='contained' color="primary" onClick={() => formik.handleSubmit()}>Save</Button>
                </Stack>
                </Stack>
            </form>
        </DialogBox>
    )
}