import Sections from '@components/sections';
import {
  Box, Button, Divider, Stack, TextField, Typography,
} from '@mui/material';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Add, Clear, Delete, Done, Edit, LibraryBooks,
} from '@mui/icons-material';
import { CheckboxSelect, ComboBox } from '@components/dropdown';
import Loading from '@components/loading';
import ListBox from '@components/list';
import { ProductsContext } from '@context/products-context';
import { colors } from '@src/config/app-config';
import {
  deleteModifier, deleteProduct, initializeProductFields,
  saveModifier, saveNewModifier, saveNewProduct, saveProduct,
} from './methods';
import { useFormik } from 'formik';
import { AppContext } from '@context/app-context';
import DialogBox from '@components/dialog-box';
import { modifierSchema, productSchema } from '@src/utils/schemas';
import { modifyString } from '@src/utils/methods';

export const ProductDetailsPanel = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const { storeId } = useContext(AppContext);
  const {
    selectedProduct, setSelectedProduct, allProducts, setAllProducts, categories, kitchenTypeOptions, vatGroupOptions,
  } = useContext(ProductsContext);

  const [searchValue, setSearchValue] = useState('');
  const [kitchenTypeSearchValue, setKitchenTypeSearchValue] = useState('');
  const saveParams = useMemo(() => ({
    setSelectedProduct,
    allProducts,
    setAllProducts,
    storeId,
    setLoading,
    handleClose,
  }), [allProducts, handleClose, setAllProducts, setSelectedProduct, storeId]);

  const deleteParams = useMemo(() => ({
    selectedProduct,
    setSelectedProduct,
    allProducts,
    setAllProducts,
    storeId,
    setLoading,
  }), [allProducts, selectedProduct, setAllProducts,
    setSelectedProduct, storeId]);

  function handleSave(values) {
    console.log();
    if (!selectedProduct || !Object.keys(selectedProduct).length === 0) {
      saveNewProduct({ product: values, ...saveParams });
    } else saveProduct({ product: values, ...saveParams });
  }

  const formik = useFormik({
    initialValues: initializeProductFields(selectedProduct),
    validationSchema: productSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
    validate: () => ({}),
    enableReinitialze: true,
  });

  const handleDiscard = () => {
    formik.setValues(initializeProductFields(selectedProduct));
    if (open) handleClose();
  };

  useEffect(() => {
    formik.setValues(initializeProductFields(selectedProduct));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  return (
    <form onSubmit={formik.handleSubmit}
      style={{
        padding: '24px 16px',
        flex: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack flex={1} direction="column" spacing={1.5}>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          <TextField sx={{ flex: 3 }} required name="name" label="Product Name" type="text" value={formik.values.name} onChange={formik.handleChange} error={formik.touched.name && Boolean(formik.errors.name)} helperText={formik.touched.name && formik.errors.name} />
          <TextField sx={{ flex: 2 }} required name="sku" label="Sku/ItemId" type="text" value={formik.values.sku} onChange={formik.handleChange} error={formik.touched.sku && Boolean(formik.errors.sku)} helperText={formik.touched.sku && formik.errors.sku} />
        </Stack>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          {!open && <TextField sx={{ flex: 1 }} name="brand" label="Brand" type="text" value={formik.values.brand} onChange={formik.handleChange} error={formik.touched.brand && Boolean(formik.errors.brand)} helperText={formik.touched.brand && formik.errors.brand} />}
          <CheckboxSelect flex={1} id="id" desc="desc" name="kitchenType" label="Kitchen Type" options={kitchenTypeOptions} value={formik.values.kitchenType} onChange={formik.setFieldValue} error={formik.touched.kitchenType && Boolean(formik.errors.kitchenType)} helperText={formik.touched.kitchenType && formik.errors.kitchenType} placeholder="Select Kitchen Type" searchValue={kitchenTypeSearchValue} setSearchValue={setKitchenTypeSearchValue} />
          {/* <TextField
            fullWidth
            required
            name="size"
            label="Size"
            type="text"
            value={formik.values.size}
            onChange={formik.handleChange}
            error={formik.touched.size && Boolean(formik.errors.size)}
            helperText={formik.touched.size && formik.errors.size}
          /> */}
        </Stack>
        {/* <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          <ComboBox required
            sx={{ flex: 1 }}
            list={list}
            name="uom"
            label="UOM"
            type="text"
            value={formik.values.uom}
            onChange={formik.handleChange}
            error={formik.touched.uom && Boolean(formik.errors.uom)}
            helperText={formik.touched.uom && formik.errors.uom}
          />
          <TextField
            name="color"
            label="Color"
            type="text"
            value={formik.values.color}
            onChange={formik.handleChange}
            error={formik.touched.color && Boolean(formik.errors.color)}
            helperText={formik.touched.color && formik.errors.color}
          />
        </Stack> */}
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          <TextField fullWidth required name="salePrice" label="Sale Price" type="text" value={formik.values.salePrice} onChange={formik.handleChange} error={formik.touched.salePrice && Boolean(formik.errors.salePrice)} helperText={formik.touched.salePrice && formik.errors.salePrice} />
          <TextField fullWidth required name="listPrice" label="List Price" type="text" value={formik.values.listPrice} onChange={formik.handleChange} error={formik.touched.listPrice && Boolean(formik.errors.listPrice)} helperText={formik.touched.listPrice && formik.errors.listPrice} />
        </Stack>
        <TextField multiline rows={4} required name="description" label="Description" type="text" value={formik.values.description} onChange={formik.handleChange} error={formik.touched.description && Boolean(formik.errors.description)} helperText={formik.touched.description && formik.errors.description} />
        <Typography variant="h6">Tax Detail</Typography>
        <ComboBox options={vatGroupOptions} name="vatGroup" label="Vat Group" type="text" value={formik.values.vatGroup} onChange={formik.handleChange} error={formik.touched.vatGroup && Boolean(formik.errors.vatGroup)} helperText={formik.touched.vatGroup && formik.errors.vatGroup} />
        <Typography variant="h6">Attach Categories</Typography>
        <CheckboxSelect id="categoryId" desc="description" name="categories" label="Categories" options={categories} value={formik.values.categories} onChange={formik.setFieldValue} error={formik.touched.categories && Boolean(formik.errors.categories)} helperText={formik.touched.categories && formik.errors.categories} placeholder="Select Categories" {...{ searchValue, setSearchValue }} />
        <Stack flex={1} direction="column" justifyContent="flex-end">
          <Stack direction="row" justifyContent="space-between">
            {selectedProduct ? (
              <Button width="70px" sx={{ color: colors.secondary }} onClick={() => (!loading ? deleteProduct(deleteParams) : null)}>
                {!loading ? <>Delete</> : <Loading color={colors.secondary} size={24} />}
              </Button>
            ) : <Box />}
            <Stack direction="row" sx={{ justifyContent: 'flex-end' }} spacing={1}>
              <Button variant="contained" color="inherit" onClick={handleDiscard}>Discard</Button>
              <Button width="60px" variant="contained" sx={{ backgroundColor: colors.primary }} onClick={!loading ? formik.handleSubmit : null}>{loading ? <Loading color="white" size={24} /> : <>Save</>}</Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export const ProductModifiersPanel = () => {
  const { selectedProduct } = useContext(ProductsContext);
  // const [isEditable, setIsEditable] = useState(false);
  // const [loading] = useState(false);
  const [modifiers, setModifiers] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [newModifierViewEnabled, setNewModifierViewEnabled] = useState(false);

  useEffect(() => {
    setModifiers(selectedProduct?.modifiers || []);
  }, [selectedProduct]);

  useEffect(() => {
    console.log('Modifiers now : ', modifiers);
  }, [modifiers]);

  useEffect(() => {
    setModifiers(selectedProduct?.modifiers?.filter(
      (item) => item.name.toLowerCase().includes(searchValue.toLowerCase()),
    ));
  }, [searchValue, selectedProduct?.modifiers]);

  const addModifier = () => {
    setNewModifierViewEnabled(true);
  };

  return (
    <Stack flex={1} overflow="hidden" direction="column">
      <ListBox.HeaderWithIconAndSearchBar
        title={`Modifiers (${selectedProduct?.modifiers?.length.toString() || 0})`}
        placeholder="Search Modifiers"
        Icon={Add}
        type={2}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onIconClick={() => addModifier()}
      />
      <Stack direction="row"
        justifyContent="space-between"
        sx={{
          py: '10px', px: '15px', backgroundColor: '#eeecfc', boxShadow: 2,
        }}
      >
        <Stack direction="row" justifyContent="flex-start" flex={2 / 3}><Typography>Name</Typography></Stack>
        <Stack direction="row" width="150px" justifyContent="center"><Typography>Price</Typography></Stack>
        <Stack direction="row" flex={1 / 3} justifyContent="flex-end"><Typography>Actions</Typography></Stack>
      </Stack>
      <Divider />
      {newModifierViewEnabled && (
      <NewModifierDialog open={newModifierViewEnabled}
        handleClose={() => setNewModifierViewEnabled(false)}
      />
      )}
      <ListBox.List sx={{ flex: 1, overflowY: 'auto' }} list={modifiers}>
        {({ item, key }) => (
          <ModifierCard key={key} modifier={item} index={key} />
        )}
      </ListBox.List>
    </Stack>
  );
};

const NewModifierDialog = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [modifierId, setModifierId] = useState('');
  const { storeId } = useContext(AppContext);

  const {
    selectedProduct, setSelectedProduct, allProducts, setAllProducts,
  } = useContext(ProductsContext);

  const saveNewParams = useMemo(() => ({
    selectedProduct,
    setSelectedProduct,
    allProducts,
    setAllProducts,
    storeId,
    setLoading,
    handleClose,
    setModifierId,
  }), [allProducts, handleClose, selectedProduct,
    setAllProducts, setSelectedProduct, storeId]);

  const formik = useFormik({
    initialValues: {
      name: '',
      price: '',
    },
    validationSchema: modifierSchema,
    onSubmit: async (values) => {
      // handle on submit fuctionality here
      await saveNewModifier({ modifier: { ...values, modifierId }, ...saveNewParams });
    },
    enableReinitialize: true,
  });

  return (
    <DialogBox open={open} title="Create New Modifier" handleClose={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogBox.Content>
          <Stack spacing={2}>
            <TextField required name="name" label="Name" type="text" value={formik.values.name} onChange={formik.handleChange} error={formik.touched.name && Boolean(formik.errors.name)} helperText={formik.touched.name && formik.errors.name} />
            <TextField required name="price" label="Price" type="text" value={formik.values.price} onChange={formik.handleChange} error={formik.touched.price && Boolean(formik.errors.price)} helperText={formik.touched.price && formik.errors.price} />
            {/* <Input value={price} setValue={setPrice} label="Price" /> */}
          </Stack>
        </DialogBox.Content>
        <DialogBox.Actions sx={{ pr: 3 }}>
          <Button variant="contained" color="inherit" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={() => (!loading ? formik.handleSubmit() : null)}>{loading ? <Loading size={24} color="white" /> : <>Save</>}</Button>
        </DialogBox.Actions>
      </form>
    </DialogBox>
  );
};

const ModifierCard = ({ modifier, index }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const { selectedProduct, setSelectedProduct, setAllProducts } = useContext(ProductsContext);
  const { storeId } = useContext(AppContext);

  const saveParams = useMemo(() => ({
    selectedProduct,
    setSelectedProduct,
    setAllProducts,
    setIsEditable,
    storeId,
    setLoading,
  }), [selectedProduct, setAllProducts, setSelectedProduct, storeId]);

  const initializeModifier = () => ({
    name: modifier.name || '',
    price: modifier.price || '',
    modifierId: modifier.modifierId || '',
  });

  const formik = useFormik({
    initialValues: initializeModifier,
    validationSchema: modifierSchema,
    onSubmit: async (values) => {
      // handle on submit fuctionality here
      console.log('Values here:', values);
      await saveModifier({
        ...saveParams,
        modifier: values,
      });
    },
  });

  useEffect(() => {
    formik.setValues(initializeModifier());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifier]);

  const deleteParams = useMemo(() => ({
    modifier,
    selectedProduct,
    setSelectedProduct,
    setAllProducts,
    setIsEditable,
    storeId,
    setLoading,
  }), [modifier, selectedProduct, setAllProducts,
    setSelectedProduct, storeId]);

  const clearModifier = () => {
    formik.setValues(initializeModifier());
    setIsEditable(false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{
          backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff',
          cursor: 'pointer',
          p: '10px',
        }}
      >
        <Box sx={{ flex: 2 / 3, display: 'flex', justifyContent: 'flex-start' }}>
          {!isEditable && <Typography variant="subtitle2">{modifyString(formik.values.name, 25)}</Typography>}
          {isEditable && <TextField size="small" required name="name" label="Name" type="text" value={formik.values.name} onChange={formik.handleChange} error={formik.touched.name && Boolean(formik.errors.name)} helperText={formik.touched.name && formik.errors.name} />}
        </Box>
        <Stack direction="row" width="150px" justifyContent="center">
          {!isEditable && <Typography variant="subtitle2">{formik.values.price}</Typography>}
          {isEditable && <TextField size="small" required name="price" label="Price" type="text" value={formik.values.price} onChange={formik.handleChange} error={formik.touched.price && Boolean(formik.errors.price)} helperText={formik.touched.price && formik.errors.price} />}
        </Stack>
        <Stack flex={1 / 3} justifyContent="flex-end" direction="row" spacing={2} alignItems="center">
          {!isEditable && <Edit onClick={() => setIsEditable(true)} sx={{ fontSize: 20, color: '#356ffb' }} />}
          {!loading && isEditable && <Done onClick={formik.handleSubmit} sx={{ fontSize: 20, color: '#356ffb' }} />}
          {loading && <Loading size={24} color={colors.secondary} />}
          {!loading && !isEditable && (
          <Delete
            onClick={() => deleteModifier(deleteParams)}
            sx={{ color: colors.secondary, fontSize: 20 }}
          />
          )}
          {isEditable && (
          <Clear onClick={() => clearModifier()}
            sx={{ color: colors.secondary, fontSize: 20 }}
          />
          )}
        </Stack>
      </Stack>
    </form>
  );
};

const tabs = [{
  label: 'Product Details',
  Icon: LibraryBooks,
  Panel: ProductDetailsPanel,
}, {
  label: 'Product Modifiers',
  Icon: LibraryBooks,
  Panel: ProductModifiersPanel,
}];

export default function ProductDetails({ hideIcon = false, ...attributes }) {
  const [value, setValue] = useState(0);
  const { selectedProduct } = useContext(ProductsContext);
  const [prevSelected, setPrevSelected] = useState();

  useEffect(() => {
    if (prevSelected?.sku !== selectedProduct?.sku) setValue(0);
    setPrevSelected(selectedProduct);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  return (
    <Box {...attributes}>
      {selectedProduct && (
        <Sections.Tabs
          value={value}
          setValue={setValue}
          tabs={tabs}
          hideIcon={hideIcon}
        />
      )}
    </Box>
  );
}
