import { endpoints } from '@src/config/api-config';
import { createAuthenticationHeaderForApi } from './groupHandlers';
import axios from 'axios';

export {
  fetchGroupsList,
  addNewSubcategory,
  getSubCategories,
  modifyGroup,
  modifySubCategory,
} from './groupHandlers';
export { getSalesSummary } from './reportHandlers';

export const getDropdownOptions = async (label) => {
  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.get(`${endpoints.groupsList}${label}`, headers);
    return data;
  } catch (err) {
    throw new Error(err.response.data.error_message);
  }
};

export const getLov = async (storeId, valueType) => {
  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.get(`${endpoints.lov}?storeId=${storeId}&valueType=${valueType}`, headers);
    return data;
  } catch (err) {
    throw new Error(err.response.data.error_message);
  }
};

export const getStoreInfo = async (userId) => {
  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.get(`${endpoints.user}/${userId}/business`, headers);
    return data;
  } catch (err) {
    throw new Error(err.response.data.error_message);
  }
};

export const getAvailableTenderTypes = async (storeId) => {
  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.get(`${endpoints.business}/${storeId}`, headers);
    return data;
  } catch (err) {
    throw new Error(err.response.data.error_message);
  }
}