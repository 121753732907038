/* eslint-disable no-unused-vars */
import {
  OutlinedInput, InputLabel, MenuItem, FormControl, Select,
  Box, FormHelperText, Checkbox, ListItemText, Autocomplete, TextField,
} from '@mui/material';
import SearchBar from './searchbar';
import { Fragment, useEffect, useState } from 'react';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import Loading from './loading';
import { getLoVItemDescription, getObjectWithDescription } from '@src/utils/methods';

const categoryTypes = [
  'DELIVERY',
  'DeliveryPartner',
];

export default function Dropdown({ ...props }) {
  return (
    <Box sx={props.sx}>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-multiple-name-label">{props.name}</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={props.val}
          size='small'
          endAdornment={props.endAdornment}
          onChange={(e) => props.setVal(e.target.value)}
          input={<OutlinedInput label={props.name} />}
        >
          {props.list.map((item) =>
            item.hasOwnProperty('label') && item.hasOwnProperty('key')
              ? (
                <MenuItem
                  key={item.key}
                  value={item.key}
                >
                  {props.showKey ? `${item.key} ${item.label}` : `${item.label}`}
                </MenuItem>
              )
              : (
                <MenuItem
                  key={item}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export const ComboBox = ({ ...props }) => (
  <Box sx={props.sx}>
    <FormControl sx={{ width: '100%' }}>
      <InputLabel id="combo-box-label" shrink error={props.error} required={props.required}>{props.label}</InputLabel>
      <Select
        labelId="combo-box-label"
        id="combo-box"
        name={props.name}
        value={props.value && props.options?.find((option) => option.id === props.value) ? props.value : ''}
        onChange={props.onChange}
        onBlur={props.onBlur}
        input={(<OutlinedInput notched label={props.label} />)}
        error={props.error}
        displayEmpty
        size={props.small ? 'small' : 'medium'}
        disabled={props.disabled}
      >
        <MenuItem value="">None</MenuItem>
        {Array.isArray(props.options) && props.options.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
          >
            {item.desc}
          </MenuItem>
        ))}
      </Select>
      {props.error && <FormHelperText sx={{ color: '#d63f3f' }}>{props.helperText}</FormHelperText>}
    </FormControl>
  </Box>
);

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export const CheckboxSelect = ({ ...attrs }) => {
  const [options] = useState(attrs.options || []);

  return (
    <Autocomplete
      multiple
      options={attrs.options}
      disableCloseOnSelect
      disablePortal
      value={getObjectWithDescription(attrs.options, attrs.value, attrs.id)}
      onChange={(_, newValue) => {
        attrs.onChange(attrs.name, newValue.map((value) => value[attrs.id]));
      }}
      getOptionLabel={(option) => option[attrs.desc]}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option[attrs.desc]}
        </li>
      )}
      sx={{ flex: attrs.flex }}
      renderInput={(params) => (
        <TextField {...params}
          label={attrs.label}
          placeholder={attrs.placeholder}
          error={attrs.error}
          required={attrs.required}
          helperText={attrs.helperText}
        />
      )}
    />
  );
};

