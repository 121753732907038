import { InputBase, Paper } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import * as React from 'react';

export default function SearchBar({
  searchValue, setSearchValue, placeholder, mx,
}) {
  return (
    <Paper
      sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mx, my: 2, boxShadow: 0, borderBottom: '1px grey solid', borderRadius: 0,
      }}
    >
      <Search sx={{ color: 'grey', fontSize: 18 }} />
      <InputBase
        placeholder={placeholder}
        inputProps={{ 'aria-label': placeholder }}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        autoFocus
        sx={{
          pl: 1, fontSize: 14, flex: 1, boxShadow: 0,
        }}
      />
      {searchValue && (
      <Clear onClick={() => setSearchValue('')}
        sx={{
          mx: 1, color: 'grey', fontSize: 18, cursor: 'pointer',
        }}
      />
      )}

    </Paper>
  );
}
