import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';

export default function DialogBox({
  children, open, title, handleClose, ...props
}) {
  return (
    <Dialog {...props} open={open} fullWidth>
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20 }}>{title}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => handleClose()}>
          <Close />
        </IconButton>
      </Box>
      {children}
    </Dialog>
  );
}

DialogBox.Header = ({ children, ...other }) => <Box {...other}>{children}</Box>;
DialogBox.Content = ({ children, ...other }) => <DialogContent {...other}>{children}</DialogContent>;
DialogBox.Actions = ({ children, ...other }) => <DialogActions {...other}>{children}</DialogActions>;
