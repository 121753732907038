import { Box, CircularProgress } from '@mui/material';

export default function Loading({ height = '100%', color = '#356ffb', size = 40, sx }) {
  return (
    <Box sx={{
      display: 'flex',
      placeContent: 'center',
      alignItems: 'center',
      height: { height },
      ...sx
    }}
    >
      <CircularProgress size={size} sx={{ color }} />
    </Box>
  );
}
