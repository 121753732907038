import { Add, ArrowRight, Close, DeleteOutline, Done, EditOutlined, ExpandCircleDownOutlined, MenuSharp, MoreVert, Settings } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Checkbox, Divider, FormControlLabel, Grid, IconButton, InputAdornment, Paper, Stack, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import useQuery from "@src/hooks/useQuery";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppConstants, colors, notify } from "@src/config/app-config";
import { useFormik } from 'formik';
import { transactionLineItemSchema } from "@src/utils/schemas";
import AddTenderLineDialog from "./add-tender-line-dialog";
import { cancelTransactionTransfer, getTransactionData, modifyTransactionData } from "@src/api/transactionHandlers";
import { ComboBox } from "@components/dropdown";
import { AppContext } from "@context/app-context";
import { getAvailableTenderTypes } from "@src/api";
import Loading from "@components/loading";
import { nanoid } from 'nanoid';
import { bignumber, subtract, number } from 'mathjs';
import dayjs from "dayjs";
import { CurrencyFormattedText, CurrencyFormattedTextString } from "@components/currency";
import voidStamp from "@src/resources/images/stock-vector-void-stamp.png"
import ActionMenuCompressed from "@components/actionsMenu"

export default function Transaction({ placeholder }) {
    const [searchValue, setSearchValue] = useState('');
    const [transDetails, setTransDetails] = useState({});
    const [pageLoading, setPageLoading] = useState(false);
    // const [loading, setLoading] = useState(false);
    // const [open, setOpen] = useState(false);

    const query = useQuery();
    const navigate = useNavigate();

    const { storeId, setTenderTypesAvailable } = useContext(AppContext);

    useEffect(() => {
        (async () => {
            try {
                const data = await getAvailableTenderTypes(storeId);
                setTenderTypesAvailable(data.details.map(item => ({
                    id: item.tender_id,
                    desc: item.name
                })))
            } catch (err) {
                notify(AppConstants.ERROR, "Unable to Fetch Available Tender Types")
            }
        })()
    }, [])

    const handleSearch = async () => {
        try {
            setPageLoading(true);
            const key = searchValue || query?.trans_id;
            const data = await getTransactionData(key);
            if ((data?.paymentLineItems ?? []).length == 0) {
                notify(AppConstants.ERROR, ('No Payment info available!!'));
            }
            data.paymentLineItems = (data.paymentLineItems ?? []).map((item, idx) => ({
                ...item,
                paymentSeq: idx + 1,
                id: nanoid(64)
            }))
            setTransDetails(data);
            navigate({ search: `?trans_id=${key}` });
        } catch (err) {
            console.log(err);
            notify(AppConstants.ERROR, ('Invalid Order No!!'));
        } finally {
            setPageLoading(false);
        }

    }

    useEffect(() => {
        if (!searchValue && query.trans_id) {
            (async () => {
                await handleSearch();
            })();
        }
    }, [])

    return <TransactionModifyScreen {...{ setSearchValue, handleSearch, transDetails, setTransDetails, pageLoading }} />
}

export const TransactionModifyScreen = ({ setSearchValue, handleSearch, transDetails, setTransDetails, pageLoading }) => {
    const [paymentLineItems, setPaymentLineItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AppContext);
    const [overrideAmtFlag, setOverrideAmtFlag] = useState(false);
    const query = useQuery();

    const showData = () => Array.isArray(transDetails.paymentLineItems) && transDetails.paymentLineItems.filter((lineItem) => lineItem.isVoid === false).length > 0;
    const showNoDataPrompt = () => query.trans_id && !showData() ? true : false;

    const navigate = useNavigate();

    useEffect(() => {
        setPaymentLineItems(transDetails?.paymentLineItems);
    }, [transDetails]);

    const getNonVoidLines = (pli) => pli?.filter((lineItem) => lineItem.isVoid === false);

    useEffect(() => {
        if (!(query?.trans_id)) {
            setTransDetails({});
            // setSearchValue('');
        }
    }, [query])

    useEffect(() => {
        setSaveButtonDisabled(
            transDetails?.total !== getNonVoidLines(paymentLineItems)?.reduce((prev, curr) => prev + curr.amount, 0) ||
            transDetails?.tipTotal !== getNonVoidLines(paymentLineItems)?.reduce((prev, curr) => prev + curr.tip, 0)
        );
    }, [transDetails, paymentLineItems])

    const handleClick = () => {
        navigate({ path: '/transactions/change_tender' })
    }

    const handleDiscard = () => {
        setPaymentLineItems(transDetails?.paymentLineItems);
    }

    const cancelTransfer = async () => {
        try {
            setLoading(true);
            await cancelTransactionTransfer(
                {
                    "transId": transDetails.transId,
                    "businessId": transDetails.storeId,
                    "action": "cancel",
                    "item": ""
                });
            await handleSearch();
            const res = notify(AppConstants.SUCCESS, "Transfers Cancelled successfully");
        } catch (err) {
            console.log(err);
            notify(AppConstants.ERROR, (err.message === '' ? 'Cancel Operation Failed' : err.message));
        } finally {
            setLoading(false)
        }
    }

    const handleSave = async () => {
        try {
            setLoading(true);
            const updatedTransDetails = {
                ...transDetails,
                paymentLineItems,
                total: getNonVoidLines(paymentLineItems)?.reduce((prev, curr) => prev + curr.amount, 0),
                tipTotal: getNonVoidLines(paymentLineItems)?.reduce((prev, curr) => prev + curr.tip, 0),
                auditedBy: user,
                amountOverriden: overrideAmtFlag
            }
            setTransDetails(updatedTransDetails);
            const res = await modifyTransactionData(updatedTransDetails);
            notify(AppConstants.SUCCESS, "Order updated successfully");
        } catch (err) {
            notify(AppConstants.ERROR, err.message);
        } finally {
            setLoading(false)
        }
    }

    return <Layout
        SearchView={<TransactionSearchScreen {...{ setSearchValue, handleSearch }} />}
        TransactionHeader={transDetails.transId && <TransactionHeader transDetails={transDetails} cancelTransfer={cancelTransfer} paymentLineItems={paymentLineItems} />}
        TableHeader={showData() && <TransactionPaymentLineHeader {...{ overrideAmtFlag, setOverrideAmtFlag, setOpen }} />}
        TableView={<Stack flex={1} direction="column">
            {!transDetails.paymentLineItems && pageLoading && <Loading />}
            {showNoDataPrompt() && !pageLoading && <NoContentInTable />}
            {showData() && <TableData {...{ paymentLineItems, setPaymentLineItems }} />}
        </Stack>
        }
        ActionsView={showData() && <Box>
            <Divider />
            <Stack direction='row' sx={{ py: 2, px: 2 }} justifyContent='flex-end' spacing={2}>
                <Button variant='contained' color='inherit' sx={{ fontWeight: 500 }} onClick={handleDiscard} >Discard</Button>
                <Button variant='contained' onClick={loading ? null : handleSave} sx={{ fontWeight: 500 }} disabled={!(!saveButtonDisabled || overrideAmtFlag)}>
                    {loading ? <Loading size={25} sx={{ px: 1 }} color='white' /> : <>Save</>}
                </Button>
            </Stack>
        </Box>}
        NewLineDialog={<AddTenderLineDialog open={open} transDetails={transDetails} setTransDetails={setTransDetails} handleClose={() => setOpen(false)} paymentLineItems={paymentLineItems} setPaymentLineItems={setPaymentLineItems} />}
    />
}

const Layout = ({ SearchView, TransactionHeader, TableHeader, TableView, ActionsView, NewLineDialog }) => {
    return <Paper sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', py: 2, overflowY: 'scroll' }}>
        {SearchView}
        {TransactionHeader}
        {TableHeader}
        {TableView}
        {ActionsView}
        {NewLineDialog}
    </Paper>
}

export const TransactionSearchScreen = ({ setSearchValue, handleSearch }) => {

    const [loading, setLoading] = useState();

    const onSearch = async () => {
        try {
            setLoading(true);
            await handleSearch();
        } finally {
            setLoading(false);
        }

    }

    return (
        <Grid container justify="flex-end">
            <Grid item xs={12} sx={{ padding: '10px 20px' }} justifyContent={'center'}>
                <Typography variant='h5'>Order Details</Typography>
            </Grid>
            <Grid item xs={12} sx={{ padding: '1px 20px' }} justifyContent={'center'}>
                <TextField
                    onChange={(e) => setSearchValue(e.target.value)}
                    label='Search Order No'
                    required
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button variant='contained'
                                    color='info'
                                    onClick={onSearch}
                                >
                                    {loading ? <Loading size={28} sx={{ px: 2 }} color='white' /> : <>Search</>}
                                </Button>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
        </Grid>
    )
}

const NoContentInTable = () => {
    return <Stack direction='column' flex={1} justifyContent='center' alignItems='center' >
        <Typography variant="h6" color='grey' >No Payment Information Available</Typography>
    </Stack>
}


const TransactionHeader = ({ transDetails, paymentLineItems, cancelTransfer }) => {
    const [updatedTotal, setUpdatedTotal] = useState();
    const [updatedTip, setUpdatedTip] = useState();
    const [showError, setShowError] = useState(false);
    const [updatedTransDetails, setUpdatedTransDetails] = useState({});
    const { allStoresDetails } = useContext(AppContext);

    useEffect(() => {
        //console.log(`rans details total : ${transDetails?.total}`);
        const nonVoidPaymentLines = transDetails?.paymentLineItems?.filter(pli => pli.isVoid !== true);
        setUpdatedTransDetails({
            ...transDetails,
            paymentLineItems: nonVoidPaymentLines,
            tipTotal: nonVoidPaymentLines?.reduce((prev, acc) => prev + acc.tip, 0)
        });
        const updatedLineItems = paymentLineItems?.filter(pli => pli.isVoid !== true);
        setUpdatedTotal(updatedLineItems?.reduce((prev, acc) => prev + acc.amount, 0));
        setUpdatedTip(updatedLineItems?.reduce((prev, acc) => prev + acc.tip, 0));
    }, [transDetails, paymentLineItems]);

    useEffect(() => {
        setShowError(updatedTransDetails?.total !== updatedTotal || updatedTransDetails?.tipTotal !== updatedTip)
    }, [updatedTransDetails, updatedTotal, updatedTip])

    const storeDetails = allStoresDetails.find(x => x.storeId === (updatedTransDetails?.storeId ?? '').toString()) ?? {
        storeId: updatedTransDetails?.storeId,
        storeName: updatedTransDetails?.storeId,
        locale: 'en_US',
        timeZone: 'Asia/Muscat'
    }

    return (
        <Box>
            <TransactionHeaderCard
                updatedTransDetails={updatedTransDetails}
                storeDetails={storeDetails}
                updatedTotal={updatedTotal}
                updatedTip={updatedTip}
                cancelTransfer={cancelTransfer} />
            <Accordion>
                <AccordionSummary
                    sx={{ flexDirection: 'row-reverse' }}
                    expandIcon={<ExpandCircleDownOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid container>
                        <Grid item xs={12} md={6} paddingLeft={2}>
                            <Typography variant="h7" fontWeight={500}>{'Order Items (Expand for details)'}</Typography>
                        </Grid>
                        <Grid item xs={4} md={2} paddingLeft={2} >
                            <Typography variant='caption' fontWeight={700}>{'Lines: '}{(updatedTransDetails?.lineItems ?? []).length}</Typography>
                        </Grid>
                        <Grid item xs={4} md={2} >
                            <Typography variant='caption' fontWeight={700}>{'Qty: '}{(updatedTransDetails?.lineItems ?? []).reduce((n, { quantity }) => n + quantity, 0)}</Typography>
                        </Grid>
                        <Grid item xs={4} md={2} >
                            <Typography variant='caption' fontWeight={700}>{'Total: '}{CurrencyFormattedTextString(updatedTransDetails.total ?? 0)}</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item padding={1} xs={1}><Typography variant='body2' fontWeight={700}>{'Qty'}</Typography></Grid>
                        <Grid item padding={1} xs={7}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography variant='body2' fontWeight={700}>{'Item'}</Typography>
                                <Typography variant='body2' fontWeight={700}>{'Price'}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item padding={1} xs={4}>
                            <Stack direction={'row'} justifyContent={'flex-end'}>
                                <Typography variant='body2' fontWeight={700}>{'Total'}</Typography>
                            </Stack></Grid>
                    </Grid>
                    {(updatedTransDetails?.lineItems ?? []).map((lineItem) =>
                    (<SaleLineItemCard
                        key={`${lineItem.lineItemSeq}_ItmDtlsCard`}
                        lineItem={lineItem}
                        storeCurrency={updatedTransDetails?.storeCurrency} />)
                    )}
                </AccordionDetails>
            </Accordion>
        </Box >
    )
}

const TableData = ({ paymentLineItems, setPaymentLineItems }) => {
    return (
        <Box id='table-data' direction='column' flex={1} >
            {(paymentLineItems?.filter((item, index) => item.isVoid === false))?.map((paymentLineItem, idx) => (
                <TransactionPaymentLineCard key={idx} {...{ paymentLineItem, setPaymentLineItems }} color={idx % 2 === 0 ? colors.oddRow : colors.evenRow} />
            ))}
        </Box>
    )
}

export const TransactionPaymentLineHeader = ({ overrideAmtFlag, setOverrideAmtFlag, setOpen }) => {
    return (
        <Box sx={{ px: 2 }}>
            <Stack direction='row' justifyContent={'space-between'}>
                <FormControlLabel
                    control={<Checkbox checked={overrideAmtFlag}
                        onChange={(e) => setOverrideAmtFlag(e.target.checked)} />}
                    sx={{ px: 2 }}
                    label="Override Transaction"
                />
                <Stack direction='row' sx={{ m: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                    <Tooltip title='Add Transaction Line' placement='bottom'>
                        <IconButton sx={{ backgroundColor: colors.primary, ':hover': { backgroundColor: colors.primary } }} onClick={() => setOpen(true)}>
                            <Add sx={{ color: 'white' }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
            <Stack direction='row' justifyContent='space-between' sx={{ p: 1 }}>
                <Stack flex={1} direction='row' justifyContent='space-between'>
                    <Typography sx={{ flex: 1 / 3, fontWeight: 600 }}>Tender Type</Typography>
                    <Typography sx={{ flex: 1 / 3, fontWeight: 600 }}>Amount</Typography>
                    <Typography sx={{ flex: 1 / 3, fontWeight: 600 }}>Tip</Typography>
                </Stack>
                <Typography sx={{ width: 80, fontWeight: 600 }}>Actions</Typography>
            </Stack>
            <Divider />
        </Box>
    )
}

export const TransactionPaymentLineCard = ({ paymentLineItem, setPaymentLineItems, color }) => {
    const [isEditable, setIsEditable] = useState(false);
    return !isEditable ?
        <TransactionPaymentLineCardNonEditable {...{ paymentLineItem, setPaymentLineItems, isEditable, setIsEditable, color }} />
        : <TransactionPaymentLineCardEditable  {...{ paymentLineItem, setPaymentLineItems, isEditable, setIsEditable, color }} />
}

const TransactionPaymentLineCardNonEditable = ({ paymentLineItem, setPaymentLineItems, isEditable, setIsEditable, color }) => {

    const handleDelete = () => {
        setPaymentLineItems(prev => prev.filter(item => item.id !== paymentLineItem.id));
    }

    return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ px: 2, backgroundColor: color }} spacing={2}>
            <Stack flex={1} direction='row' justifyContent='space-between' spacing={2}>
                <Typography variant="caption" fontWeight={500} sx={{ flex: 1 / 3, py: 1.10, px: 0.75 }}>{paymentLineItem.tenderId}</Typography>
                <Typography variant="caption" fontWeight={500} sx={{ flex: 1 / 3, py: 1.10, px: 0.75 }}>{CurrencyFormattedTextString(paymentLineItem.amount, paymentLineItem.currencyId ?? 0)}</Typography>
                <Typography variant="caption" fontWeight={500} sx={{ flex: 1 / 3, py: 1.10, px: 0.75 }}>{CurrencyFormattedTextString(paymentLineItem.tip, paymentLineItem.currencyId ?? 0)}</Typography>
            </Stack>
            <Stack direction='row' sx={{ width: 80 }}>
                <IconButton onClick={() => setIsEditable(true)}  ><EditOutlined /></IconButton>
                <IconButton onClick={handleDelete}><DeleteOutline /></IconButton>
            </Stack>
        </Stack>
    )
}


const TransactionPaymentLineCardEditable = ({ paymentLineItem, setPaymentLineItems, isEditable, setIsEditable, color }) => {

    const { tenderTypesAvailable } = useContext(AppContext);

    const initializeValues = () => ({
        tenderId: paymentLineItem?.tenderId || '',
        amount: paymentLineItem.amount?.toString() ? paymentLineItem.amount : 0,
        tip: paymentLineItem.tip?.toString() ? paymentLineItem.tip : 0,
        receivedAmount: paymentLineItem.receivedAmount?.toString() ? paymentLineItem.receivedAmount : 0,
    })

    const handleSubmit = (values) => {
        const tipDiff = subtract(bignumber(paymentLineItem.tip), bignumber(values.tip));
        const newAmount = subtract(bignumber(values.amount), bignumber(tipDiff));
        const newReceivedAmount = values.tenderId.toLowerCase() === 'cash' ? subtract(bignumber(values.receivedAmount), bignumber(tipDiff)) : number(values.receivedAmount);

        const updatedLineItem = {
            ...paymentLineItem,
            ...values,
            amount: number(newAmount),
            receivedAmount: number(newReceivedAmount),
            tip: number(values.tip),
            changeAmount: number(values.receivedAmount) > 0 ? subtract(bignumber(values.receivedAmount), bignumber(values.amount)) : 0
        };

        if (updatedLineItem.tip > updatedLineItem.amount) {
            notify(AppConstants.ERROR, "Tip cannot be greater than amount");
            return;
        }
        setPaymentLineItems(prev => prev.map((item) => item.id === paymentLineItem.id ? updatedLineItem : item));
        setIsEditable(false);
    }

    const formik = useFormik({
        initialValues: initializeValues(),
        validationSchema: transactionLineItemSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
        validate: () => ({}),
        enableReinitialze: true,
    });

    return (
        <form>
            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ p: 1, backgroundColor: color }}>
                <Stack flex={1} direction='row' justifyContent='space-between' spacing={1} sx={{ pr: 1.5 }}>
                    <ComboBox sx={{ flex: 1 / 3 }} disabled options={tenderTypesAvailable} name="tenderId" label="Tender Type" type="text" value={formik.values.tenderId} onChange={formik.handleChange} error={formik.touched.tenderId && Boolean(formik.errors.tenderId)} helperText={formik.touched.tenderId && formik.errors.tenderId} />
                    {formik.values.tenderId.toLowerCase() !== 'cash' && <TextField disabled sx={{ flex: 1 / 3 }} required name="amount" label={`Amount [${paymentLineItem.currencyId}]`} type="text" value={formik.values.amount} onChange={formik.handleChange} error={formik.touched.amount && Boolean(formik.errors.amount)} helperText={formik.touched.amount && formik.errors.amount} />}
                    {formik.values.tenderId.toLowerCase() === 'cash' && <TextField disabled sx={{ flex: 1 / 6 }} name="amount" label="Amount" type="text" value={formik.values.amount} onChange={formik.handleChange} error={formik.touched.amount && Boolean(formik.errors.amount)} helperText={formik.touched.amount && formik.errors.amount} />}
                    {formik.values.tenderId.toLowerCase() === 'cash' && <TextField disabled sx={{ flex: 1 / 6 }} required name="receivedAmount" label="Recieved Amount" type="text" value={formik.values.receivedAmount} onChange={formik.handleChange} error={formik.touched.receivedAmount && Boolean(formik.errors.receivedAmount)} helperText={formik.touched.receivedAmount && formik.errors.receivedAmount} />}
                    <TextField sx={{ flex: 1 / 3 }} name="tip" label={`Tip [${paymentLineItem.currencyId}]`} type="text" value={formik.values.tip} onChange={formik.handleChange} error={formik.touched.tip && Boolean(formik.errors.tip)} helperText={formik.touched.tip && formik.errors.tip} />
                </Stack>
                <Stack direction='row' sx={{ width: 80 }}>
                    <IconButton onClick={() => setIsEditable(false)} ><Close /></IconButton>
                    <IconButton onClick={() => formik.handleSubmit()} ><Done sx={{ color: colors.primary }} /></IconButton>
                </Stack>
            </Stack>
        </form>
    )
}

const TransactionHeaderCard = ({ updatedTransDetails, storeDetails, updatedTotal, updatedTip, cancelTransfer }) => {
    return (
        <Card sx={{ padding: '10px 20px' }}>
            <Grid container>
                <Grid item sx={{ padding: '5px' }} xs={6} md={3}><Typography variant='h6'>{`Order No`}</Typography></Grid>
                <Grid item sx={{ padding: '5px' }} xs={6} md={9}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant='h6'>{updatedTransDetails?.transId}</Typography>
                        <ActionMenuCompressed
                            menuIcon={<MoreVert style={{ color: '#0288d1' }} />}
                            menuList={[
                                {
                                    key: 'cancel',
                                    disabled: updatedTransDetails?.status === 'cancelled',
                                    label: 'Cancel Order',
                                    action: cancelTransfer,
                                    showConfirmDialog: true,
                                    confirmDialogTitle: `Confirm Cancellation`,
                                    confirmDialogDescription: `Do you want to cancel order ${updatedTransDetails?.transId}?`,
                                }
                            ]} />
                    </Stack>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item sx={{ padding: '5px' }} xs={6}><Typography variant='body2'>{`Status`}</Typography></Grid>
                        <Grid item sx={{ padding: '5px' }} xs={6}><Typography variant='body2'>{updatedTransDetails?.status}</Typography></Grid>
                        <Grid item sx={{ padding: '5px' }} xs={6}><Typography variant='body2'>{` Order Date`}</Typography></Grid>
                        <Grid item sx={{ padding: '5px' }} xs={6}><Typography variant='body2'>
                            {dayjs((updatedTransDetails?.businessDate ?? 0) / 1000).tz(storeDetails.timeZone).format('DD-MMM-YYYY')}
                        </Typography>
                        </Grid>
                        <Grid item sx={{ padding: '5px' }} xs={6}><Typography variant='body2'>{`Store`}</Typography></Grid>
                        <Grid item sx={{ padding: '5px', overflow: "hidden", textOverflow: "ellipsis" }} xs={6}><Typography variant='body2'>{storeDetails.storeId} {storeDetails.storeName}</Typography></Grid>
                        <Grid item sx={{ padding: '5px' }} xs={6}><Typography variant='body2'>{`CustomerName`}</Typography></Grid>
                        <Grid item sx={{ padding: '5px', overflow: "hidden", textOverflow: "ellipsis" }} xs={6}><Typography variant='body2'>{updatedTransDetails?.customerName ?? ''}</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item sx={{ padding: '5px' }} xs={6}><Typography variant='body2'>{`Sub Total`}</Typography></Grid>
                        <Grid item sx={{ padding: '5px' }} xs={6}>
                            <Typography variant='body2' noWrap>
                                {CurrencyFormattedTextString(updatedTransDetails?.subtotal ?? 0, updatedTransDetails?.storeCurrency)}
                            </Typography>
                        </Grid>
                        <Grid item sx={{ padding: '5px' }} xs={6}><Typography variant='body2'>{`Tax`}</Typography></Grid>
                        <Grid item sx={{ padding: '5px' }} xs={6}>
                            <Typography variant='body2' noWrap>
                                {CurrencyFormattedTextString(updatedTransDetails?.taxTotal ?? 0, updatedTransDetails?.storeCurrency)}</Typography></Grid>
                        <Grid item sx={{ padding: '5px' }} xs={6}><Typography variant='body2'>{`Tip`}</Typography>
                        </Grid>
                        <Grid item sx={{ padding: '5px' }} xs={6}>
                            {updatedTransDetails?.tipTotal !== updatedTip
                                ? (
                                    <Stack direction={'row'} spacing={3}>
                                        <Typography variant='body2' noWrap>
                                            {CurrencyFormattedTextString(updatedTransDetails?.tipTotal ?? 0, updatedTransDetails?.storeCurrency)}</Typography>
                                        <ArrowRight variant='body2' />
                                        <Typography variant='body2' color='error' noWrap>
                                            {CurrencyFormattedTextString(updatedTip ?? 0, updatedTransDetails?.storeCurrency)}</Typography>
                                    </Stack>)
                                : (
                                    <Stack direction={'row'}>
                                        <Typography variant='body2' noWrap>
                                            {CurrencyFormattedTextString(updatedTransDetails?.tipTotal ?? 0, updatedTransDetails?.storeCurrency)}</Typography>
                                    </Stack>)
                            }
                        </Grid>
                        <Grid item sx={{ padding: '5px' }} xs={6}><Typography variant='body2'>{`Total`}</Typography></Grid>
                        <Grid item sx={{ padding: '5px' }} xs={6}>
                            {(updatedTransDetails?.total !== updatedTotal) ? (
                                <Stack direction={'row'} spacing={3}>
                                    <Typography variant='body2' noWrap>
                                        {CurrencyFormattedTextString(updatedTransDetails?.total ?? 0, updatedTransDetails?.storeCurrency)}</Typography>
                                    <ArrowRight variant='body2' />
                                    <Typography variant='body2' color='error' noWrap>
                                        {CurrencyFormattedTextString(updatedTotal ?? 0, updatedTransDetails?.storeCurrency)}</Typography>
                                </Stack>)
                                : (
                                    <Stack direction={'row'}>
                                        <Typography variant='body2' noWrap>
                                            {CurrencyFormattedTextString(updatedTransDetails?.total ?? 0, updatedTransDetails?.storeCurrency)}</Typography>
                                    </Stack>)
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>);
}

const SaleLineItemCard = ({ lineItem, storeCurrency }) => {
    return (
        <Box>
            <Divider />
            <Box key={`${lineItem.lineItemSeq}_ItmDtlsGrid`}
                sx={(lineItem.isVoid ?
                    {
                        background: `url(${voidStamp})`,
                        backgroundSize: '100px',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }
                    : {})}>
                <Grid container sx={(lineItem.isVoid ? { backgroundColor: `rgba(0, 0, 0, 0.2)`, } : {})}>
                    <Grid padding={1} key={`${lineItem.lineItemSeq}_Qty`} item xs={1}>
                        <Box style={{ marginTop: '-5px' }}>
                            <Typography key={`${lineItem.lineItemSeq}_QtyVal`} variant='caption' fontWeight={700}>
                                {`${lineItem.quantity ?? 0}`}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid padding={1} key={`${lineItem.lineItemSeq}_ItmDtlsUnitPrc`} item xs={7}>
                        <Stack direction={'column'}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography variant='caption' fontWeight={700}>{`${lineItem.itemDescription ?? lineItem.itemId}`}</Typography>
                                <CurrencyFormattedText
                                    key={`${lineItem.lineItemSeq}_QtyVal`}
                                    variant='caption'
                                    fontWeight={700}
                                    currency={storeCurrency}
                                    value={lineItem.totalBaseUnitPrice} />
                            </Stack>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography variant='caption'>{'Base Price'}</Typography>
                                <CurrencyFormattedText
                                    key={`${lineItem.lineItemSeq}_QtyVal`}
                                    variant='caption'
                                    currency={storeCurrency}
                                    fontWeight={500}
                                    value={lineItem.baseUnitPrice} />
                            </Stack>
                            {lineItem.additionalModifier && lineItem.additionalModifier.length > 0 ?
                                (<>
                                    <Typography variant='caption'>{'+ Add-ons'}</Typography>
                                    {(lineItem.additionalModifier ?? []).map((addOn) => (
                                        <Stack key={`${addOn.uuid}_Stack`} direction={'row'} justifyContent={'space-between'}>
                                            <Stack direction={'row'}>
                                                <Typography key={`${addOn.uuid}_Qty`} variant='caption'>
                                                    {`${addOn.quantity}`}
                                                </Typography>
                                                <Typography key={`${addOn.uuid}_Dsc`} variant='caption'>
                                                    {`x ${addOn.description ?? addOn.name}`}
                                                </Typography>
                                            </Stack>
                                            <Typography key={`${addOn.uuid}_Val`} variant='caption' noWrap>
                                                {`(+${CurrencyFormattedTextString(addOn.price ?? 0, storeCurrency)})`}
                                            </Typography>
                                        </Stack>
                                    ))}
                                </>) : <></>}
                            {lineItem.lineModifiers && lineItem.lineModifiers.length > 0 ?
                                (<>
                                    <Typography variant='caption'>{'-Discounts'}</Typography>
                                    {(lineItem.lineModifiers ?? []).map((lineMod) => (
                                        <Stack key={`${lineMod.lineItemModSeq}_Desc`} direction={'row'} justifyContent={'space-between'}>
                                            <Typography key={lineMod.lineItemModSeq} variant='caption' overflow={'hidden'}>
                                                {`${lineMod.description}`}
                                            </Typography>
                                            <Typography key={`${lineMod.lineItemModSeq}_Amt`} variant='caption'>
                                                {`(-${CurrencyFormattedTextString(lineMod.amount ?? 0, storeCurrency)})`}
                                            </Typography>
                                        </Stack>
                                    ))}</>)
                                : <></>}
                        </Stack>
                    </Grid>
                    <Grid padding={1} key={`${lineItem.lineItemSeq}_TotalPrc`} item xs={4}>
                        <Stack key={`${lineItem.lineItemSeq}_SubTotal`} direction={'row'} justifyContent={'space-between'} spacing={2}>
                            <Typography key={`${lineItem.lineItemSeq}_TotalPrcDsc`} variant='caption' fontWeight={700}>
                                {``}
                            </Typography>
                            <CurrencyFormattedText key={`${lineItem.lineItemSeq}_TotalPrcVal`}
                                variant='caption'
                                fontWeight={700}
                                currency={storeCurrency}
                                value={lineItem.grossAmount} />
                        </Stack>
                    </Grid>
                </Grid >
            </Box>
        </Box>
    )
}