import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, IconButton, DialogContentText, Paper,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/app-context';
import Dropdown from './dropdown';

export default function SelectStoreDialog({ open, handleCancel }) {
  const { storeId, setStoreId, allStoresDetails, storesAvailable } = useContext(AppContext);
  const [value, setValue] = useState(storeId || '');

  const storesAvailableDetails = [];
  storesAvailable.forEach((storeId) => {
    storesAvailableDetails.push(
      {
        key: storeId,
        label: allStoresDetails.find(x => x.storeId === storeId).storeName ?? storeId
      }
    );
  });

  const onProceed = () => {
    setStoreId(value);
    localStorage.setItem('storeId', value);
    handleCancel();
  };

  useEffect(() => {
    if (!storeId) { setStoreId(localStorage.getItem('storeId')); }
  }, [setStoreId, storeId]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20 }}>Store Selection</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => handleCancel()}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ pt: 0 }}>
        <DialogContentText sx={{ fontSize: 16 }}>Please select your store-id to proceed</DialogContentText>
        <br />
        <Paper component="form" onSubmit={onProceed}>
          <Dropdown name="StoreId"
            list={storesAvailableDetails}
            showKey={true}
            val={value}
            setVal={setValue}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" variant="contained" onClick={() => handleCancel()}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={onProceed}>
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
}
