import axios from 'axios';
import { Auth } from 'aws-amplify';
import { endpoints } from '../config/api-config';

const debug = process.env.REACT_APP_ENV === 'dev';
console.log(process.env.REACT_APP_ENV);
console.log(process.env.REACT_APP_ENV === 'dev');

const logger = (type, apiName, object) => {
  if (debug) {
    console.debug(`---------------------------${apiName} API ${type} START--------------------------------------`);
    console.debug(JSON.stringify(object));
    console.debug(`---------------------------${apiName} API ${type} END--------------------------------------`);
  }
};

const REQUEST = 'REQUEST';
const RESPONSE = 'RESPONSE';

const createAuthenticationHeaderForApi = async () => {
  try {
    const session = await Auth.currentSession();
    const token = session.accessToken.jwtToken;

    return {
      headers: {
        Authorizer: `Bearer ${token}`,
      },
    };
  } catch (e) {
    return {};
  }
};

export const getSalesSummary = async (fromDate, toDate, storeId) => {
  logger(REQUEST, 'FETCH_SALES_REPORT', fromDate, toDate, storeId);
  try {
    const headers = await createAuthenticationHeaderForApi();
    const fromDateISO = new Date(fromDate * 1000).toISOString();
    // const toDateISO = new Date(toDate);
    const toDateISO = new Date(toDate * 1000).toISOString();
    const { data } = await axios.get(`${endpoints.getSalesSummary}/${storeId}/report/sales?from=${fromDateISO}&to=${toDateISO}`, headers);
    logger(RESPONSE, 'FETCH_SALES_REPORT', data);
    return data;
  } catch (err) {
    console.error('FETCH_SALES_REPORT API FAILED : ', err.message);
    throw err;
  }
};

export const getSalesPosting = async (fromDate, toDate, storeId) => {
  logger(REQUEST, 'FETCH_SALES_POSTING_REPORT', fromDate, toDate, storeId);
  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.get(`${endpoints.getSalesSummary}/${storeId}/posting?from=${fromDate * 1000}&to=${toDate * 1000}`, headers);
    logger(RESPONSE, 'FETCH_SALES_POSTING_REPORT', data);
    return data;
  } catch (err) {
    console.error('FETCH_SALES_POSTING_REPORT API FAILED : ', err.message);
    throw err;
  }
};

export const getSalesRecon = async (fromDate, toDate, storeId) => {
  logger(REQUEST, 'FETCH_SALES_RECON_REPORT', fromDate, toDate, storeId);
  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.get(`${endpoints.getSalesSummary}/${storeId}/recon?from=${fromDate * 1000}&to=${toDate * 1000}`, headers);
    logger(RESPONSE, 'FETCH_SALES_RECON_REPORT', data);
    return data;
  } catch (err) {
    console.error('FETCH_SALES_RECON_REPORT API FAILED : ', err.message);
    throw err;
  }
};

export const getShiftSummary = async (fromDate, toDate, storeId) => {
  logger(REQUEST, 'FETCH_SHIFT_REPORT', fromDate, toDate, storeId);
  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.get(`${endpoints.getSalesSummary}/${storeId}/summary?from=${fromDate * 1000}&to=${toDate * 1000}`, headers);
    logger(RESPONSE, 'FETCH_SHIFT_REPORT', data);
    return data;
  } catch (err) {
    console.error('FETCH_SHIFT_REPORT API FAILED : ', err.message);
    throw err;
  }
}
