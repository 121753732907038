import { getSubCategories, modifyGroup, modifySubCategory } from '../../api';
import { AppConstants, notify } from '../../config/app-config';
import { listSort } from '../../utils/methods';

export const handleDeleteSubcategoryFromGroup = async ({
  storeId, subCategory, selectedGroup, setSelectedGroup, setLoading, setAllGroups,
}) => {
  try {
    setLoading(true);
    await modifySubCategory(AppConstants.DELETE, {
      storeId,
      categoryId: subCategory.categoryId,
      groupId: selectedGroup.groupId,
    });
    const group = {
      ...selectedGroup,
      categories: selectedGroup.categories.filter((item) => item.categoryId !== subCategory.categoryId),
    };
    setSelectedGroup(group);
    setAllGroups((prev) => prev.map((item) => (item.groupId === group.groupId ? group : item)));
  } catch (err) {
    notify(AppConstants.ERROR, err.message);
  } finally {
    setLoading(false);
  }
};

export const analyseSettledResponses = (selectedCategories, responses) => {
  const rejectedCategories = [];
  if (selectedCategories.length === responses.length) {
    for (let i = 0; i < responses.length; i += 1) {
      if (responses[i].status === 'rejected') {
        rejectedCategories.push(selectedCategories[i]);
        notify(AppConstants.ERROR, `Error creating subcategory with id ${selectedCategories[i].categoryId}`);
      }
    }
  }
  const filteredCategories = selectedCategories.filter((item) => {
    const result = rejectedCategories.find((i) => i.categoryId === item.categoryId);
    if (result) return false;
    return true;
  });
  return filteredCategories;
};

export const handleAddMultipleSubcategoriesToGroup = async ({
  storeId, selectedCategories, selectedGroup, setSelectedGroup, setIsNewCategoryAdded, setLoading, setAllGroups,
}) => {
  try {
    setLoading(true);
    const promiseQueue = [];
    selectedCategories.forEach((subCategory) => {
      promiseQueue.push(modifySubCategory(AppConstants.UPDATE, {
        ...subCategory,
        parent: [selectedGroup.groupId],
        storeId,
      }));
    });
    const responses = await Promise.allSettled(promiseQueue);
    const filteredCategories = analyseSettledResponses(selectedCategories, responses);
    const group = {
      ...selectedGroup,
      categories: Array.isArray(selectedGroup.categories)
        ? [...filteredCategories, ...selectedGroup.categories]
        : filteredCategories,
    };
    setAllGroups((prev) => {
      const filteredGroups = prev.filter((item) => item.groupId !== selectedGroup.groupId);
      return listSort([group, ...filteredGroups]);
    });
    setSelectedGroup(group);
    setIsNewCategoryAdded(false);
  } catch (err) {
    notify(AppConstants.ERROR, err.message);
  } finally {
    setLoading(false);
  }
};

export const handleModifySingleSubcategoryToGroup = async ({ ...props }) => {
  try {
    props.setLoading(true);
    const payload = {
      ...props.category,
      sortOrder: Number(props.category.sortOrder),
      parent: [props.selectedGroup.groupId],
      storeId: props.storeId,
    };
    await modifySubCategory(props.type, payload);
    let categories = [];
    const found = props.selectedGroup.categories?.find((c) => c?.categoryId === props.category?.categoryId);
    if (found) {
      categories = props.selectedGroup.categories?.map(
        (item) => (item.categoryId === props.category.categoryId ? props.category : item),
      );
    } else if (Array.isArray(props.selectedGroup?.categories) && props.selectedGroup.categories?.length > 0) {
      categories = [props.category, ...props.selectedGroup.categories];
    } else {
      categories = [props.category];
    }
    const group = { ...props.selectedGroup, categories };
    props.setAllGroups((prev) => prev.map((grp) => (grp.groupId === group.groupId ? group : grp)));
    props.setSelectedGroup(group);
    if (props.type === AppConstants.ADD) {
      props.setIsNewCategoryAdded(false);
      notify(AppConstants.SUCCESS, 'Subcategory added successfully');
    }
    if (props.type === AppConstants.UPDATE) {
      props.setIsEditable(false);
      notify(AppConstants.SUCCESS, 'Subcategory updated successfully');
    }
  } catch (err) {
    notify(AppConstants.ERROR, err.message);
  } finally {
    props.setLoading(false);
  }
};

export const showAddNewSubcategoryDialog = async ({
  storeId, categories, setCategories, setIsNewCategoryAdded, setLoading,
}) => {
  try {
    setLoading(true);
    if (!(Array.isArray(categories) && categories.length > 0)) {
      const { Items } = await getSubCategories(storeId);
      // console.log("Categories here :", Items);
      setCategories(Items);
    }
    setIsNewCategoryAdded(true);
  } catch (err) {
    notify(AppConstants.ERROR, err.message);
  } finally {
    setLoading(false);
  }
};

export const handleGroupDelete = async ({
  storeId, selectedGroup, setSelectedGroup, allGroups, setAllGroups, setLoading,
}) => {
  try {
    setLoading(true);
    await modifyGroup('DELETE', {
      storeId,
      groupId: selectedGroup.groupId,
      categories: selectedGroup.categories,
    });
    setAllGroups((prev) => {
      const updatedGroups = prev.filter((item) => item.groupId !== selectedGroup.groupId);
      return updatedGroups;
    });

    setSelectedGroup((prev) => {
      const index = allGroups.indexOf(prev);
      if (index + 1 < allGroups.length) return allGroups[index + 1];
      if (index - 1 >= 0 && index - 1 < allGroups.length) return allGroups[index - 1];
      return allGroups[0];
    });
    notify(AppConstants.SUCCESS, 'Group deleted successfully');
  } catch (err) {
    notify(AppConstants.ERROR, err.message);
  } finally {
    setLoading(false);
  }
};

export const refreshGroupFields = ({
  selectedGroup, setGroupId, setCategoryType, setSortOrder, setDescription, setIsEditingAllowed,
}) => {
  setGroupId(selectedGroup?.groupId || '');
  setCategoryType(selectedGroup?.categoryType || '');
  setSortOrder(selectedGroup?.sortOrder.toString() || '');
  setDescription(selectedGroup?.description || '');
  setIsEditingAllowed(false);
};

export const initializeGroupFields = (selectedGroup) => ({
  groupId: selectedGroup?.groupId || '',
  color: selectedGroup?.color || '',
  sortOrder: selectedGroup?.sortOrder || '',
  categoryType: selectedGroup?.categoryType || '',
  description: selectedGroup?.description || '',
});

export const initializeCategoryFields = (category) => ({
  categoryId: category?.categoryId || '',
  sortOrder: category?.sortOrder || '',
  color: category?.color || '',
  categoryType: category?.categoryType || '',
  description: category?.description || '',
});

export const handleGroupSave = async ({ ...props }) => {
  try {
    console.log('Group save called');
    props.setLoading(true);
    const payload = {
      ...props.group,
      sortOrder: Number(props.group.sortOrder),
      storeId: props.storeId,
    };
    if (!props.selectedGroup) {
      await modifyGroup(AppConstants.ADD, payload);
      props.setAllGroups((prev) => listSort([props.group, ...prev]));
    } else {
      await modifyGroup(AppConstants.UPDATE, payload);
      props.setAllGroups((prev) => {
        const filteredGroups = prev.filter((item) => item.groupId !== props.selectedGroup.groupId);
        return listSort([props.group, ...filteredGroups]);
      });
    }
    props.setSelectedGroup(props.group);
    props.setIsEditingAllowed(false);
    notify(AppConstants.SUCCESS, 'Group added successfully');
  } catch (err) {
    notify(AppConstants.ERROR, err.message);
  } finally {
    props.setLoading(false);
  }
};

export const refreshSubcategoryFields = ({
  subCategory, setSubcategoryId, setCategoryType, setSortOrder, setDescription, setIsEditable,
}) => {
  setIsEditable(false);
  setSubcategoryId(subCategory?.categoryId || '');
  setCategoryType(subCategory?.categoryType || '');
  setSortOrder(subCategory?.sortOrder?.toString() || '');
  setDescription(subCategory?.description || '');
};

export const refreshSubcategoryDialogFields = ({
  setSearchValue, setSelectedCategories, setSubcategoryId, setCategoryType, setSortOrder, setDescription,
}) => {
  setSearchValue('');
  setSelectedCategories([]);
  setSubcategoryId('');
  setCategoryType('');
  setSortOrder('');
  setDescription('');
};

export const handleDiscardSubcategoryDialog = ({
  setSearchValue, setSelectedCategories, setSubcategoryId, setCategoryType,
  setSortOrder, setDescription, setIsNewCategoryAdded,
}) => {
  refreshSubcategoryDialogFields({
    setSearchValue, setSelectedCategories, setSubcategoryId, setCategoryType, setSortOrder, setDescription,
  });
  setIsNewCategoryAdded(false);
};
