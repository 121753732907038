// AppContext.js
import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';
import { Auth } from 'aws-amplify';
import { AppConstants, notify } from '../config/app-config';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  // const [activeRoute, setActiveRoute] = useState('');
  const [storeId, setStoreId] = useState();
  const [storesAvailable, setStoresAvailable] = useState([]);
  const [allStoresDetails, setAllStoresDetails] = useState([]);
  const [user, setUser] = useState();
  const [tenderTypesAvailable, setTenderTypesAvailable] = useState([]);

  const clearAppContext = () => {
    setIsApplicationLoading(false);
    setIsSidebarExpanded(false);
    setStoreId(null);
    setUser(null);
  };

  useEffect(() => {
    (async () => {
      try {
        const { username } = await Auth.currentUserInfo();
        setUser(username);
      } catch (e) {
        notify(AppConstants.ERROR, e.msg);
      }
    })();
  }, []);

  const contextValue = useMemo(
    () => ({
      user,
      setUser,
      storesAvailable,
      setStoresAvailable,
      isApplicationLoading,
      setIsApplicationLoading,
      isSidebarExpanded,
      setIsSidebarExpanded,
      storeId,
      setStoreId,
      tenderTypesAvailable,
      setTenderTypesAvailable,
      clearAppContext,
      allStoresDetails,
      setAllStoresDetails,
    }),
    [isApplicationLoading, isSidebarExpanded, storeId, storesAvailable, user, tenderTypesAvailable],
  );

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
