import axios from 'axios';
import { Auth } from 'aws-amplify';
import { endpoints } from '../config/api-config';
import { AppConstants } from '../config/app-config';

const debug = process.env.REACT_APP_ENV === 'dev';

const REQUEST = 'REQUEST';
const RESPONSE = 'RESPONSE';

export const createAuthenticationHeaderForApi = async () => {
  try {
    const session = await Auth.currentSession();
    const token = session.accessToken.jwtToken;

    return {
      headers: {
        Authorizer: `Bearer ${token}`,
      },
    };
  } catch (e) {
    return {};
  }
};
export const logger = (type, apiName, object) => {
  if (debug) {
    console.debug(`---------------------------${apiName} API ${type} START--------------------------------------`);
    console.debug(JSON.stringify(object));
    console.debug(`---------------------------${apiName} API ${type} END--------------------------------------`);
  }
};

export const fetchGroupsList = async (storeId, payload = {}) => {
  logger(REQUEST, 'FETCH_GROUPS_LIST', payload);

  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.get(`${endpoints.groupsList}/${storeId}`, headers);
    logger(RESPONSE, 'FETCH_GROUPS_LIST', data);
    return data;
  } catch (err) {
    console.error('FETCH_GROUPS_LIST API FAILED : ', err.message);
    throw new Error(err.response.data.error_message);
  }
};

export const getSubCategories = async (storeId, payload = {}) => {
  logger(REQUEST, 'FETCH_GROUPS_LIST', payload);

  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.get(`${endpoints.getSubCategories}/${storeId}`, headers);
    logger(RESPONSE, 'FETCH_GROUPS_LIST', data);
    return data;
  } catch (err) {
    console.error('FETCH_GROUPS_LIST API FAILED : ', err.message);
    throw new Error(err.response.data.error_message);
  }
};

export const addNewSubcategory = async (payload = {}) => {
  logger(REQUEST, 'ADD_NEW_CATEGORY', payload);

  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.post(endpoints.modifyCategory, JSON.stringify(payload), headers);
    logger(RESPONSE, 'ADD_NEW_CATEGORY', data);
    return data;
  } catch (err) {
    console.error('ADD_NEW_CATEGORY API FAILED : ', err.message);
    throw new Error(err.response.data.error_message);
  }
};

export const modifyGroup = async (type, payload) => {
  logger(REQUEST, `${type}_GROUP`, payload);
  try {
    const headers = await createAuthenticationHeaderForApi();
    let res;
    if (type === AppConstants.ADD) {
      res = await axios.post(endpoints.modifyGroups, payload, headers);
    } else if (type === AppConstants.UPDATE) {
      res = await axios.put(endpoints.modifyGroups, payload, headers);
    } else if (type === AppConstants.DELETE) {
      res = await axios.delete(endpoints.modifyGroups, { data: payload, ...headers });
    }
    logger(RESPONSE, `${type}_GROUP`, res?.data);
    return res?.data;
  } catch (err) {
    console.error(`${type}_GROUP API FAILED : ${err.message}`);
    throw new Error(err.response.data.error_message);
  }
};

export const modifySubCategory = async (type, payload) => {
  logger(REQUEST, `${type}_CATEGORY`, payload);
  try {
    const headers = await createAuthenticationHeaderForApi();
    let res;
    console.log('Type : ', type);
    if (type === AppConstants.ADD) {
      res = await axios.post(endpoints.modifyCategory, payload, headers);
    } else if (type === AppConstants.UPDATE) {
      res = await axios.put(endpoints.modifyCategory, payload, headers);
    } else if (type === AppConstants.DELETE) {
      res = await axios.put(endpoints.removeCategory, payload, headers);
    }
    logger(RESPONSE, `${type}_CATEGORY`, res);
    return res;
  } catch (err) {
    console.error(`${type}_CATEGORY API FAILED : ${err.message}`);
    throw new Error(err.response.data.error_message);
  }
};
