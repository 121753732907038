export const listSort = (items) => {
  if (Array.isArray(items) && items.length > 0) {
    return items.sort(
      (a, b) => (a.sortOrder - b.sortOrder !== 0
        ? a.sortOrder - b.sortOrder
        : a.groupId.localeCompare(b.groupId)),
    );
  }
  return [];
};

export const unixTimeToHhMmSs = (duration) => {
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? `0${hours}` : hours;
  minutes = (minutes < 10) ? `0${minutes}` : minutes;
  seconds = (seconds < 10) ? `0${seconds}` : seconds;

  const formattedTime = `${hours}Hr ${minutes}Min ${seconds}Sec`;
  return formattedTime;
};

export const modifyString = (string, len) => {
  if (string.length > len) return string.substring(0, len).concat('...');
  return string;
};

function padString(number, padLength) {
  return number.toString().padStart(padLength, '0');
}

export const getLoVItemDescription = (options, id) => options?.find((option) => option.id === id);
export const getObjectWithDescription = (options, list, param = 'id') => options?.filter((option) => list?.includes(option[[param]]));
