import { Component, useContext } from 'react';
import { DateFilterWidget, getFromDateFromFilter, getToDateFromFilter } from './dateFilter';
import { AppContext } from '@context/app-context';
import { Box, Card, CircularProgress, Stack, } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { getSalesPosting } from '@src/api/reportHandlers';
import EnhancedTable from '@components/enhancedDataGrid';

export default function TenderReport() {
  const { storeId, allStoresDetails } = useContext(AppContext);
  const storeDetails = allStoresDetails.find(x => x.storeId === storeId) ?? {
    storeId,
    storeName: storeId,
    locale: 'en_US',
    timeZone: 'Asia/Muscat'
  }
  const [searchParams] = useSearchParams();
  const filterParams = Object.fromEntries([...searchParams]);
  return (
    <TenderReportView
      storeId={storeId}
      storeDetails={storeDetails}
      currentFromDate={filterParams.currentFromDate}
      currentToDate={filterParams.currentToDate}
    />
  );
}
class TenderReportView extends Component {
  constructor(props) {
    super(props);
    const { storeId, storeDetails } = this.props;
    this.loadReports = this.loadReports.bind(this);
    this.state = {
      storeId,
      reportData: {},
      status: 'LOADING',
      storeDetails
    };
  }

  componentDidMount() {
    const { currentFromDate, currentToDate } = this.props;
    if (currentFromDate == null || currentToDate == null) {
      this.loadReports(getFromDateFromFilter('TODAY'), getToDateFromFilter('TODAY'));
    } else {
      this.loadReports(currentFromDate, currentToDate,
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.storeId !== prevProps.storeId) {
      this.componentDidMount();
    }
  }

  loadReports(fromDate, toDate) {
    const { storeId } = this.props;
    this.setState({
      reportData: null,
      status: 'LOADING',
    });
    getSalesPosting(fromDate, toDate, storeId).then((data) => {
      this.setState({
        reportData: data,
        storeId,
        status: 'LOADED',
      });
    }).catch((err) => {
      console.log('Error while fetching SalesPosting', err.message);
      this.setState({
        reportData: null,
        storeId,
        status: 'ERROR',
      });
    });
  }


  render() {
    const { reportData, status } = this.state;
    const { storeId, storeDetails } = this.props;
    const { currentFromDate, currentToDate } = this.props;
    let dataTemp = reportData?.paymentAgg?.paymentGroups ?? [];
    let data = [];

    let i = 0;
    dataTemp.forEach(datapoint => {
      data.push({ ...datapoint, id: i, name: datapoint.method });
      i++;
    });

    const headCells = [
      { id: 'method', numeric: true, disablePadding: true, label: 'Payment Method', },
      { id: 'amount', numeric: true, disablePadding: false, label: 'Amount (Less Change)', },
      { id: 'tip', numeric: true, disablePadding: false, label: 'Tip', },
      { id: 'totalWOTip', numeric: true, disablePadding: false, label: 'Amount (Less Gratuity)', },
    ];


    return (
      <Box
        sx={{
          backgroundColor: '#e2e2e2',
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Stack direction="column" sx={{ height: '100%', paddingX: 1, paddingY: 0 }} spacing={1}>
          <Box>
            <Card>
              <DateFilterWidget
                fetchReports={this.loadReports}
                fromDate={currentFromDate}
                toDate={currentToDate}
                storeDetails={storeDetails}
              />
            </Card>
          </Box>
          {status === 'LOADING' ?
            (<Stack direction="row" sx={{ height: '100%', width: '100%', paddingX: 2, paddingY: 1 }} spacing={2} justifyContent="center">
              <CircularProgress />
            </Stack>)
            : status === 'ERROR' ?
              (<Stack direction="row" justifyContent="center">Opps! We encountered an issue while fetching the report data!!</Stack >)
              : data ?
                <Box style={{
                  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                  borderRadius: '4px',
                  backgroundColor: 'white'
                }}>
                  <EnhancedTable
                    headCells={headCells}
                    rows={data}
                    title={'SALES POSTING - Tenders'}
                    downloadFileName={`${storeDetails.storeName}_${storeId}_SALES_POSTING_Tenders.csv`} />
                </Box>
                :
                <>No Data</>}
        </Stack>
      </Box>
    )
  }
}
