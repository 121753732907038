import { useRef, useState, useEffect } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { IconButton } from '@mui/material';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

export default function MenuListComposition({ menuLabel, menuIcon, menuList }) {
  const [open, setOpen] = useState(false);
  const [confOpen, setConfOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const anchorRef = useRef(null);


  const showConfirmDialog = () => {
    setConfOpen(true);
  };

  const hideDialog = () => {
    console.log(1);
    setConfOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <IconButton
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          sx={{ borderRadius: 100 }}
        >{menuIcon}
          {menuLabel}
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{ border: '1px solid #0288d1' }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {menuList.map((option) => (
                      <MenuItem
                        disabled={option.disabled ?? false}
                        key={option.key}
                        onClick={(event) => {
                          setDialogProps(option);
                          handleClose(event);
                          (option.showConfirmDialog ?? false) && showConfirmDialog();
                        }}>
                        {option.label}
                      </MenuItem>)
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {confOpen && (
          <Dialog
            open={confOpen}
            onClose={hideDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{dialogProps.confirmDialogTitle ?? 'Confirm Action'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogProps.confirmDialogDescription ?? 'Are you sure about this action?'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  hideDialog();
                  dialogProps.action();
                }}
                color="primary">
                Yes
              </Button>
              <Button onClick={hideDialog} color="primary">
                No
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </Stack >
  );
}