import * as React from 'react';
import RaisedBox from '../../components/raised-box';
import SearchBar from '../../components/searchbar';
import {
  Box, Divider, IconButton, Stack, Typography,
} from '@mui/material';
import { GroupsContext, GroupsProvider } from '../../context/groups-context';
import { useContext, useState } from 'react';
import GroupsList from './list-section';
import GroupInfo from './info-section';
import { Add } from '@mui/icons-material';

export default function Groups() {
  const [searchValue, setSearchValue] = useState('');

  return (
    <GroupsProvider>
      <Box sx={{ backgroundColor: '#e2e2e2', width: '100%', height: '100%' }}>
        <Stack direction="row" sx={{ height: '100%', paddingX: 2, paddingY: 1 }} spacing={2}>
          <RaisedBox flex={3} sx={{ pt: 1, borderRadius: 1 }}>
            <GroupHeader size={20} padding="20px 0px" />
            <Divider />
            <SearchBar {...{
              searchValue, setSearchValue, placeholder: 'Search Group Name', mx: 2,
            }}
            />
            <Divider />
            <GroupsList {...{ searchValue, setSearchValue }} />
          </RaisedBox>
          <RaisedBox flex={7}>
            <GroupInfo />
          </RaisedBox>
        </Stack>
      </Box>
    </GroupsProvider>
  );
}

export const GroupHeader = () => {
  const { invokeAddNewGroupEvent } = useContext(GroupsContext);
  return (
    <Box sx={{
      display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 2, py: 1,
    }}
    >
      <Typography variant="h6">Groups</Typography>
      <IconButton onClick={invokeAddNewGroupEvent}
        sx={{
          backgroundColor: '#356ffb',
          color: 'white',
          minWidth: 0,
          minHeight: 0,
          p: 0.5,
          ':hover': { backgroundColor: '#356ffb' },
        }}
      >
        <Add />

      </IconButton>
    </Box>
  );
};
