const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

export const endpoints = {
  business: `${baseUrl}/business`,
  groupsList: `${baseUrl}/group`,
  getSubCategories: `${baseUrl}/categories`,
  modifyGroups: `${baseUrl}/group/modify`,
  modifyCategory: `${baseUrl}/categories/modify`,
  removeCategory: `${baseUrl}/categories/remove`,
  getSalesSummary: `${baseUrl}/business`,
  getProducts: `${baseUrl}/product`,
  modifyProduct: `${baseUrl}/product/modify`,
  modifyModifier: `${baseUrl}/product/modifier/modify`,
  lov: `${baseUrl}/lov`,
  user: `${baseUrl}/user`,
};
