import { Component, useContext } from 'react';
import { AppContext } from '@context/app-context';
import { Box, Button, Card, CircularProgress, FormHelperText, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { listStoreTransactions } from '@src/api/transactionHandlers';
import useQuery from '@src/hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ArrowRightAltRounded, Search } from '@mui/icons-material';
import dayjs from 'dayjs';
import EnhancedTable from '@components/enhancedDataGrid';
import { CurrencyFormattedTextString } from '@components/currency';
import Dropdown from '@components/dropdown';
import orderStatuses from '../../config/orderStatuses.json';

export default function TransactionLookup() {
    const { storeId, allStoresDetails } = useContext(AppContext);
    const query = useQuery();
    const navigate = useNavigate();
    const storeDetails = allStoresDetails.find(x => x.storeId === storeId) ?? {
        storeId,
        storeName: storeId,
        locale: 'en_US',
        timeZone: 'Asia/Muscat'
    }
    return (
        <TransactionLookupView
            storeId={storeId}
            storeDetails={storeDetails}
            currentFromDate={query?.fromDate}
            currentToDate={query?.toDate}
            filterStatus={query?.filterStatus}
            navigate={navigate}
        />
    );
}

class TransactionLookupView extends Component {
    constructor(props) {
        super(props);
        const { storeId, storeDetails, currentFromDate, currentToDate, filterStatus } = this.props;
        this.timeZone = storeDetails.timeZone
        this.selectedFromDate = currentFromDate ?? dayjs().tz(this.timeZone).startOf('day').unix();
        this.selectedToDate = currentToDate ?? dayjs().tz(this.timeZone).unix();
        this.state = {
            storeId,
            reportData: [],
            storeDetails,
            status: 'LOADED',
            currentPage: 0,
            rowsPerPage: 50,
            paginationToken: undefined,
            limitReached: false,
            filterStatus: filterStatus ?? 'all'
        };
        this.loadReports = this.loadReports.bind(this);
    }

    componentDidMount() {
        this.loadReports({ reset: true });
    }

    componentDidUpdate(prevProps) {
        if (this.props.storeId !== prevProps.storeId) {
            this.componentDidMount();
        }
    }

    setFilterStatus = (value) => {
        this.loadReports({ reset: true, status: value });
    }

    setFromDate(value) {
        this.selectedFromDate = value.tz(this.timeZone).startOf('day').unix();
        this.loadReports({ reset: true });
    }

    setToDate(value) {
        value = value.unix() > dayjs().unix() ? dayjs() : value.tz(this.timeZone).hour(23).minute(59).second(59);
        this.selectedToDate = value.unix();
        this.loadReports({ reset: true });
    }

    getFromDate() {
        //return formatISO(new Date(this.selectedFromDate * 1000))
        return dayjs.utc(this.selectedFromDate * 1000);
    }

    getToDate() {
        //return formatISO(new Date(this.selectedToDate * 1000))
        return dayjs.utc(this.selectedToDate * 1000);
    }

    loadReports({ currentPage, rowsPerPage, reset, status }) {
        const { storeId, storeDetails, navigate } = this.props;
        const { reportData, paginationToken, filterStatus } = this.state;
        console.log('Loading reports');
        console.log('rowsPerPage', rowsPerPage);
        this.setState({
            status: 'LOADING'
        });
        listStoreTransactions({
            fromDate: this.selectedFromDate,
            toDate: this.selectedToDate,
            businessId: storeId,
            timeZone: storeDetails.timeZone,
            rows: rowsPerPage ? rowsPerPage * 2 : 100,
            paginationToken: reset ? undefined : paginationToken,
            status: status ?? filterStatus
        }).then((data) => {
            const processedData = reset ? [] : reportData;
            if (data.items.length !== 0) {
                data.items.forEach(element => {
                    processedData.push({
                        ...element._source,
                        id: element._source.transId,
                        businessDate: dayjs(element._source.businessDate).tz(this.timeZone).format('YYYY-MMM-DD'),
                        grossSubTotal: CurrencyFormattedTextString(element._source.grossSubTotal, storeDetails.currency),
                        tipTotal: CurrencyFormattedTextString(element._source.tipTotal, storeDetails.currency),
                        total: CurrencyFormattedTextString(element._source.total, storeDetails.currency)
                    })
                });
                this.setState({
                    reportData: processedData,
                    storeId,
                    status: 'LOADED',
                    paginationToken: data.paginationToken,
                    currentPage: currentPage,
                    rowsPerPage,
                    filterStatus: status ?? filterStatus,
                    limitReached: false
                });
                navigate({ search: `?fromDate=${this.selectedFromDate}&?toDate=${this.selectedToDate}&filterStatus=${status ?? filterStatus}` });
            } else {
                this.setState({
                    status: 'LOADED',
                    currentPage: currentPage,
                    rowsPerPage,
                    limitReached: true
                });
            }
        }).catch((err) => {
            console.log('Error while fetching ItemSales', err.message);
            this.setState({
                reportData: null,
                storeId,
                status: 'ERROR',
            });
        });
    }

    render() {
        const headCells = [
            {
                id: 'id',
                hyperlink: true,
                label: 'Order No',
                path: (id) => { return `/transactions/change_tender?trans_id=${id}` },
            },
            { id: 'status', label: 'Status', },
            { id: 'associateId', label: 'Associate', },
            { id: 'customerName', label: 'CustomerName', },
            { id: 'businessDate', label: 'Date' },
            { id: 'grossSubTotal', label: 'Subtotal', },
            { id: 'tipTotal', label: 'Tip', },
            { id: 'total', label: 'Total', },
        ];

        const { reportData, status, currentPage, rowsPerPage, limitReached, filterStatus } = this.state;
        const { storeDetails } = this.props;

        return (
            <Box
                sx={{
                    backgroundColor: '#e2e2e2',
                    width: '100%',
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <Stack direction="column" sx={{ height: '100%', paddingX: 1, paddingY: 0 }} spacing={1}>
                    <Box>
                        <Card>
                            <LocalizationProvider dateAdapter={AdapterDayjs} timeZone={storeDetails.timeZone}>
                                <Grid container spacing={1} padding={1}>
                                    <Grid item xs={12} md={4} justifyContent={'center'} alignItems="center">
                                        <FormHelperText></FormHelperText>
                                        <Typography variant="h6" align='center' padding={1}>{storeDetails.storeId}  {storeDetails.storeName}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={5} justifyContent={'center'}>
                                        <Stack direction="row" justifyContent="space-around">
                                            <Box justifyContent={'center'}>
                                                <FormHelperText>From Date ({this.timeZone})</FormHelperText>
                                                <DatePicker
                                                    slotProps={{ textField: { size: 'small' } }}
                                                    value={this.getFromDate()}
                                                    onChange={(newValue) => this.setFromDate(newValue)}
                                                    maxDate={dayjs().subtract(1, 'second')}
                                                    timezone={this.timeZone}
                                                />
                                            </Box>
                                            <Box paddingTop={4}>
                                                <ArrowRightAltRounded style={{ margin: 'auto 5px' }} />
                                            </Box>
                                            <Box justifyContent={'center'}>
                                                <FormHelperText>To Date ({this.timeZone})</FormHelperText>
                                                <DatePicker
                                                    slotProps={{ textField: { size: 'small' } }}
                                                    value={this.getToDate()}
                                                    onChange={(newValue) => this.setToDate(newValue)}
                                                    maxDate={dayjs()}
                                                    timezone={this.timeZone}
                                                />
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={3} justifyContent={'center'} alignItems="center">
                                        <FormHelperText>Status</FormHelperText>
                                        <Dropdown
                                            list={orderStatuses}
                                            val={filterStatus}
                                            setVal={this.setFilterStatus}
                                        />
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </Card>
                    </Box>
                    <Paper>
                        {status === 'LOADING' ?
                            (<Stack direction="row" sx={{ height: '100%', width: '100%', paddingX: 2, paddingY: 1 }} spacing={2} justifyContent="center">
                                <CircularProgress />
                            </Stack>)
                            : status === 'ERROR' ?
                                (<Stack direction="row" justifyContent="center">Opps! We encountered an issue while fetching the report data!!</Stack >)
                                : (reportData?.length ?? 0) > 0 ?
                                    <EnhancedTable
                                        headCells={headCells}
                                        rows={reportData}
                                        title={'Orders'}
                                        loadMore={this.loadReports}
                                        currentPage={currentPage}
                                        limitReached={limitReached}
                                        currentRowsPerPage={rowsPerPage} />
                                    : (<div>No Data Available</div>)
                        }
                    </Paper>
                </Stack>
            </Box>
        );
    }
}
