import { AppConstants } from '@src/config/app-config';
import { createAuthenticationHeaderForApi, logger } from './groupHandlers';
import axios from 'axios';
import { endpoints } from '@src/config/api-config';

export const getProductsList = async (storeId, lastEvaluatedKey) => {
  logger(AppConstants.REQUEST, 'FETCH_GROUPS_LIST', {});
  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.get(`${endpoints.getProducts}/${storeId}?pageSize=25&lastEvaluatedKey=${lastEvaluatedKey}`, headers);
    logger(AppConstants.RESPONSE, 'FETCH_GROUPS_LIST', data);
    return data;
  } catch (err) {
    console.log(`Error fetching products${err}`);
    throw new Error(err.response.data.error_message);
  }
};

export const modifyProduct = async (type, payload) => {
  logger(AppConstants.REQUEST, 'FETCH_GROUPS_LIST', {});
  try {
    const headers = await createAuthenticationHeaderForApi();
    let res;
    if (type === AppConstants.ADD) {
      res = await axios.post(`${endpoints.modifyProduct}`, payload, headers);
    } else if (type === AppConstants.UPDATE) {
      res = await axios.put(`${endpoints.modifyProduct}`, payload, headers);
    } else if (type === AppConstants.DELETE) {
      res = await axios.delete(`${endpoints.modifyProduct}`, { data: payload, ...headers });
    }
    logger(AppConstants.RESPONSE, 'FETCH_GROUPS_LIST', res?.data);
    return res?.data;
  } catch (err) {
    console.log('Error fetching products:', err);
    throw new Error(err.response.data.error_message);
  }
};

export const modifyModifier = async (type, payload) => {
  logger(AppConstants.REQUEST, 'FETCH_GROUPS_LIST', {});
  console.log('Type : ', type);
  try {
    const headers = await createAuthenticationHeaderForApi();
    let res;
    if (type === AppConstants.ADD) {
      res = await axios.post(`${endpoints.modifyModifier}`, payload, headers);
    } else if (type === AppConstants.UPDATE) {
      console.log('Updating Modifier', payload);
      res = await axios.put(`${endpoints.modifyModifier}`, payload, headers);
    } else if (type === AppConstants.DELETE) {
      res = await axios.delete(`${endpoints.modifyModifier}`, { data: payload, ...headers });
    }
    logger(AppConstants.RESPONSE, 'FETCH_GROUPS_LIST', res?.data);
    return res?.data;
  } catch (err) {
    console.log(`Error fetching products${err.message}`);
    throw new Error(err.response.data.error_message);
  }
};

export const getModifiersList = async (storeId) => {
  logger(AppConstants.REQUEST, 'FETCH_GROUPS_LIST', {});
  try {
    const headers = await createAuthenticationHeaderForApi();
    const { data } = await axios.get(`${endpoints.groupsList}/${storeId}`, headers);
    logger(AppConstants.RESPONSE, 'FETCH_GROUPS_LIST', data);
    return data;
  } catch (err) {
    console.log(`Error fetching products${err.message}`);
    throw new Error(err.response.data.error_message);
  }
};
