// GroupsContext.js
import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { AppContext } from './app-context';
import { getLov } from '@src/api';
import { AppConstants } from '@src/config/app-config';

const GroupsContext = createContext();

const GroupsProvider = ({ children }) => {
  const [selectedGroup, setSelectedGroup] = useState();
  const [allGroups, setAllGroups] = useState();
  const [setIsAddNewGrpEnabled] = useState(false);
  const [categories, setCategories] = useState();
  const [categoryTypeOptions, setCategoryTypeOptions] = useState([]);

  const { user, storeId } = useContext(AppContext);

  const invokeAddNewGroupEvent = () => {
    console.log('Add new group invoked');
    setSelectedGroup(null);
    setCategories(null);
  };

  const clearGroupsContext = useCallback(() => {
    setSelectedGroup(null);
    setAllGroups(null);
    setIsAddNewGrpEnabled(false);
    setCategories(null);
  }, [setIsAddNewGrpEnabled]);

  useEffect(() => {
    (async () => {
      try {
        const options = await getLov(storeId, AppConstants.CATEGORY_TYPE);
        setCategoryTypeOptions(options);
      } catch (err) {
        setCategoryTypeOptions([]);
      }
    })();
  }, [storeId]);

  useEffect(() => {
    if (!storeId && !user) { clearGroupsContext(); }
  }, [clearGroupsContext, storeId, user]);

  const contextValue = useMemo(
    () => ({
      selectedGroup,
      setSelectedGroup,
      allGroups,
      setAllGroups,
      invokeAddNewGroupEvent,
      categories,
      setCategories,
      categoryTypeOptions,
      setCategoryTypeOptions,
      clearGroupsContext,
    }),
    [allGroups, categories, categoryTypeOptions, clearGroupsContext, selectedGroup],
  );

  return (
    <GroupsContext.Provider value={contextValue}>
      {children}
    </GroupsContext.Provider>
  );
};

export { GroupsContext, GroupsProvider };
