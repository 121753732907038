import * as React from 'react';
import Loading from '../../components/loading';
import { Box, Stack } from '@mui/material';
import { fetchGroupsList } from '../../api';
import { GroupsContext } from '../../context/groups-context';
import { useContext, useEffect, useState } from 'react';
import GroupCard from './group-card';
import { AppContext } from '../../context/app-context';
import { AppConstants, notify } from '../../config/app-config';
import { listSort } from '../../utils/methods';

export default function GroupsList({ searchValue }) {
  const { allGroups, setAllGroups, setSelectedGroup } = useContext(GroupsContext);
  const { storeId } = useContext(AppContext);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { Items } = await fetchGroupsList(storeId);
        setAllGroups(listSort(Items));
        setSelectedGroup(() => {
          if (Array.isArray(Items) && Items.length > 0) {
            return Items[0];
          }
          return null;
        });
        setGroups(Items);
      } catch (err) {
        notify(AppConstants.ERROR, err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [setAllGroups, setSelectedGroup, storeId]);

  useEffect(() => {
    if (!searchValue) {
      setGroups(allGroups);
    }
    const filteredGroups = allGroups?.filter((item) => item.groupId?.toLowerCase()
      .includes(searchValue?.toLowerCase()));
    setGroups(filteredGroups);
  }, [allGroups, searchValue]);

  useEffect(() => {
    setGroups(allGroups);
  }, [allGroups]);

  return loading ? (<Loading color="#356ffb" />) : (
    <Box id="scrollable-container" sx={{ overflowY: 'auto', borderRadius: 1 }}>
      <Stack direction="column">
        {groups?.map((group, index) => (
          <GroupCard key={index} group={group} />
        ))}
      </Stack>
    </Box>

  );
}
