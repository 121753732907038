import Loading from '@components/loading';
import { AppContext } from '@context/app-context';
import { Close, Download } from '@mui/icons-material';
import { Box, Button, Card, Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import { Document, Page, StyleSheet, View, Text, PDFViewer, Image } from '@react-pdf/renderer';
import { getShiftSummary } from '@src/api/reportHandlers';
import { colors, getTranslation } from '@src/config/app-config';
import React, { useContext, useRef, useState, useEffect } from 'react';

export default function ShiftReport({ fromDate, toDate }) {

    const [summary, setSummary] = useState();
    const [openReport, setOpenReport] = useState(false);
    const [loading, setLoading] = useState(false);

    const { allStoresDetails, storeId } = useContext(AppContext);

    const store = allStoresDetails.find(x => x.storeId === storeId) ?? {
        storeId,
        storeName: storeId,
        locale: 'en_US',
        timeZone: 'Asia/Muscat'
    }
    // console.log("Store Now : ", store);

    const getShiftReport = async () => {
        try {
            setLoading(true);
            const data = await getShiftSummary(fromDate, toDate, storeId);
            // console.log("Response : ", data);
            setSummary(data);
            setOpenReport(true);
        } catch (e) {
            console.log("Error : ", e.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
    }, [openReport])

    return (
        <Card sx={{
            height: '90px',
            cursor: 'pointer',
            backgroundColor: colors.primary,
            paddingX: 2,
            paddingY: 1,
            margin: 1
        }}
        >
            {!loading &&
                <Stack direction='row' sx={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }} spacing={1} onClick={getShiftReport}>
                    <Typography variant='h6' color='white'>Shift Report</Typography>
                    <Download sx={{ color: 'white' }} />
                </Stack>}
            {loading &&
                <Stack direction='row' sx={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }} spacing={1}>
                    <Loading color='white' />
                </Stack>
            }
            {/* { openReport &&  <ShiftSummaryReportPDF />} */}
            {summary && <Dialog
                open={openReport}
                onClose={() => setOpenReport(false)}
                fullScreen={true}
                style={{
                    zIndex: 10000,
                }}
                sx={{
                    width: '60%',
                    height: '70%',
                    mx: '20%',
                    mt: '5%'
                }}
            >
                {/* <Typography>Shift Report</Typography> */}
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: '100%',
                    width: '100%'
                }}>
                    <PDFViewer style={{ height: "100%", width: "100%" }}>
                        <TestPDF data={summary} store={store} />
                    </PDFViewer>
                </Box>
            </Dialog>}
        </Card>
    )
}

const styles = StyleSheet.create({
    page: {
        width: 230, // Width in points (1 inch = 72 points)
        padding: 10,
        fontFamily: 'Helvetica',
    },
    logoContainer: {
        display: 'flex',
        width: 230,
        justifyItems: 'center'
    },
    logo: {
        width: 180,
    },
    header: {
        fontSize: 11,
        textAlign: 'center',
        marginBottom: 10,
    },
    content: {
        fontSize: 10,
        marginBottom: 5,
    },
    section: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});


const statistics = {
    label: 'Statistics',
    keys: ['validReceipts', 'voidReceipts', 'openAccountsCount', 'totalAccounts', '', 'draftReceipts', 'duplicateReceipts', 'directSales', 'tableSales', '', 'coversCount']
}

const sections = [{
    label: 'Statistics',
    keys: ['validReceipts', 'voidReceipts', 'openAccountsCount', 'totalAccounts', '', 'draftReceipts', 'duplicateReceipts', 'directSales', 'tableSales', '', 'coversCount']
}, {
    label: 'Sales',
    keys: ['daySales', 'voidReceiptsVal', 'voidItemsVal', 'ownSales', '', 'totalWODiscount', 'totalSales']
}, {
    label: 'Payments',
    keys: ['receivedPayment', 'voidedPayments', 'surcharges', 'ownPayments', '', 'receivedTip', 'cashTip', 'laterTip', 'dueCash']
},]

export const TestPDF = ({ data, store }) => {
    console.log("Data : ", data, store);
    return (
        <Document>
            <Page title='Shift Report' style={styles.page} size={[230]}>
                <View style={{ flexDirection: "row", justifyContent: "center" }}>
                    <Image src='/assets/images/cafeLogo.jpg' style={styles.logo} />
                </View>
                <View style={{ flexDirection: "row", justifyContent: "center", ...styles.header }}>
                    <Text>{store.storeName}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "center", ...styles.header }}>
                    <Text>Shift Report</Text>
                </View>
                <View style={styles.content}>
                    <DottedLineBreak />
                    {sections.map((section, id) =>
                        <View>
                            <View style={{ flexDirection: "row", justifyContent: "center" }}>
                                <Text>{section.label}</Text>
                            </View>
                            <EmptyLineBreak />
                            {section.keys.map((key, idx) => (
                                <View key={idx} style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                    <Text>{getTranslation(key, "en")}</Text>
                                    <Text>{data[key]?.toString() || ''}</Text>
                                </View>
                            ))}
                            <DottedLineBreak />
                        </View>
                    )}

                    {/* Takings Section -Start */}

                    <View style={{ flexDirection: "row", justifyContent: "center" }}>
                        <Text>Takings</Text>
                    </View>
                    <EmptyLineBreak />

                    {Object.keys(data.paymentDetails).map((method, key) => (
                        <View key={key}>
                            <View>
                                <Text>{method}</Text>
                            </View>
                            {Object.keys(data.paymentDetails[method]).map((key, idx) => (
                                <View key={idx} style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                    <Text>{getTranslation(key, "en")}</Text>
                                    <Text>{data.paymentDetails[method][key]}</Text>
                                </View>)
                            )}
                            <DottedLineBreak />
                        </View>
                    ))}

                    {Object.keys(data.tipDetails).map((method, key) => (
                        <View key={key}>
                            <View>
                                <Text>{method}</Text>
                            </View>
                            {Object.keys(data.tipDetails[method]).map((key, idx) => (
                                <View key={idx} style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                    <Text>{getTranslation(key, "en")}</Text>
                                    <Text>{data.tipDetails[method][key]}</Text>
                                </View>)
                            )}
                            <DottedLineBreak />
                        </View>
                    ))}

                    {/* Tasking Section - End */}
                    <View style={{ flexDirection: "row", justifyContent: "center" }}>
                        <Text>Open Accounts</Text>
                    </View>
                    <EmptyLineBreak />

                    {Array.isArray(data.openAccounts) && data.openAccounts.map((account, key) => (
                        <View key={key}>
                            <View>
                                <Text>
                                    {account.section ? account.section + ', ' : ''}
                                    {account.table ? 'Table' + ' ' + account.table : ''}
                                </Text>
                            </View>
                            {['sold', 'paid', 'unpaid'].map((item, idx) => (
                                <View key={idx} style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                    <Text>{getTranslation(item, "en")}</Text>
                                    <Text>{account[item]}</Text>
                                </View>
                            ))}
                        </View>
                    ))}
                </View>

            </Page>
        </Document>
    )
}


const DottedLineBreak = () => {
    return (
        <View>
            <EmptyLineBreak />
            <Text
                style={{
                    width: '100%',
                    height: 1,
                    borderTop: '1pt dotted #000',
                }}
            >
                {' '}
            </Text>
            <EmptyLineBreak />
        </View>
    );
};

const EmptyLineBreak = () => (
    <View>
        <Text>{' '}</Text>
    </View>
)