/* eslint-disable no-unused-vars */
import { Box, Grid, Stack, Typography } from '@mui/material';
import { CustomizedAxisTick, SalesSummaryDataCardColored, SummaryDataCardTopEntries } from './widgets';
import { unixTimeToHhMmSs } from '@src/utils/methods';
import { Card, Divider } from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import { CurrencyFormattedText, CurrencyFormattedTextString } from '@components/currency';
import { Link } from 'react-router-dom';
import { Launch } from '@mui/icons-material';

const SalesDetailsReport = ({
  reportData, status, fromDate, toDate, storeDetails
}) => {
  if (status === 'LOADED') {
    const dataPointSales = [];
    reportData.order_timeline.buckets.forEach((element) => {
      dataPointSales.push({
        name: dayjs(element.key).tz(storeDetails.timeZone).format('DD-MMM-YYYY hh:mm:ssA'),
        Revenue: parseFloat(element.total.value.toFixed(2)),
        Orders: element.doc_count,
        RevenueFormatted: CurrencyFormattedTextString(element.total.value, storeDetails.currency, true),
      });
    });

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Card style={{
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
            borderRadius: '4px',
          }}>
            <Box style={{ margin: '10px auto' }}>
              <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: '550' }}>Revenue & Orders</Typography>
              <Divider orientation="horizontal" />
              {dataPointSales && dataPointSales.length > 0 ?
                (<ResponsiveContainer height={475}>
                  <BarChart
                    height={410}
                    data={dataPointSales}
                    barCategoryGap={5}
                    margin={{
                      top: 50,
                      right: 0,
                      left: 0,
                      bottom: 120,
                    }}
                  >
                    <Bar
                      yAxisId="left"
                      type="monotone"
                      dataKey="Revenue"
                      fill="#92bbdb"
                      strokeWidth={3}
                      radius={[5, 5, 0, 0]}
                    >
                      <LabelList dataKey="RevenueFormatted" angle="-90" fill="black" dy={-30} fontSize={12} fontWeight={550} />
                    </Bar>
                    <Bar
                      yAxisId="right"
                      type="monotone"
                      dataKey="Orders"
                      fill="#8BC34A"
                      strokeWidth={3}
                      radius={[5, 5, 0, 0]}
                    >
                      <LabelList dataKey="Orders" angle="-90" fill="black" dy={-30} fontSize={12} fontWeight={550} />
                    </Bar>
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="name" tick={<CustomizedAxisTick />} interval={0} />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                  </BarChart>
                </ResponsiveContainer>)
                : <Typography variant='subtitle1' fontWeight={700}>No data available</Typography>
              }
              <Link to={`/reports/salesRecon?currentFromDate=${fromDate}&currentToDate=${toDate}`} style={{ textDecoration: 'none', color: 'black' }} target='_blank'>
                <Stack direction="row" justifyContent="center">
                  <Launch style={{ margin: '4px' }} />
                  <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: '500' }}>Sales Reconciliation Report</Typography>
                </Stack>
              </Link>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card style={{
                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                borderRadius: '4px',
              }}>
                <Grid container justifyContent={'center'}>
                  <Grid item xs={12} md={4} justifyContent={'center'}>
                    <SalesSummaryDataCardColored title="Average Table Occupancy" unit="per table" data={unixTimeToHhMmSs(reportData.table_time.avg ?? 0)} color="#0099cc" />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <SalesSummaryDataCardColored title="Revenue" unit="per cover"
                      data={<CurrencyFormattedText
                        value={(reportData?.cover_total?.sum ?? 0) === 0 ? 0
                          : ((reportData?.total_revenue?.sum ?? 0) - (reportData?.tip_total?.sum ?? 0)) / (reportData?.cover_total?.sum ?? 1)} />}
                      color="#FF6E40" />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <SalesSummaryDataCardColored title="Revenue" unit="per order" data={<CurrencyFormattedText value={reportData.total_revenue.avg ?? 0} />} color="#8BC34A" />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SalesSummaryDataCardColored title="Covers" unit="Total" data={reportData?.cover_total?.sum ?? 0} color="#800080" />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SummaryDataCardTopEntries title="Top Tenders" valueFormatter="CURRENCY" data={reportData.tenders.tender_type} detailReportId="teders" detailReportLabel="Sales Posting - Tenders" detailReportIpath={`/reports/tenders?currentFromDate=${fromDate}&currentToDate=${toDate}`} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SummaryDataCardTopEntries title="Top Food Groups" valueFormatter="CURRENCY" data={reportData.top_category.total_qty} detailReportId="foodgroupsales" detailReportLabel="Sales Posting - Accounting Groups" detailReportIpath={`/reports/salesPostAccounts?currentFromDate=${fromDate}&currentToDate=${toDate}`} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <SummaryDataCardTopEntries title="Top Sold Items" data={reportData.top_items.total_qty} detailReportId="itemsales" detailReportIpath={`/reports/itemsales?currentFromDate=${fromDate}&currentToDate=${toDate}`} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SummaryDataCardTopEntries title="Top Associates" valueField="DOC_COUNT" data={reportData.top_associate} detailReportId="associates" detailReportIpath={`/reports/associates?currentFromDate=${fromDate}&currentToDate=${toDate}`} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SummaryDataCardTopEntries title="Top Category" valueFormatter="CURRENCY" data={reportData.top_group.total_qty} detailReportId="foodcategorysales" />
        </Grid>
      </Grid>
    );
  }
  return (<div>Loading...</div>);
};

export default SalesDetailsReport;

