import {
  Add, Delete, Edit,
} from '@mui/icons-material';
import {
  Box, Stack, Button, Typography, Divider, Chip, TextField, Grid,
} from '@mui/material';
import { GroupsContext } from '../../context/groups-context';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { AppContext } from '../../context/app-context';
import Loading from '../../components/loading';
import NewSubCategoryDialog from './new-subcategory-dialog';
import { ComboBox } from '../../components/dropdown';
import {
  handleGroupDelete, showAddNewSubcategoryDialog, initializeGroupFields, handleGroupSave,
} from './methods';
import { colors } from '../../config/app-config';
import { useFormik } from 'formik';
import { groupSchema } from '@src/utils/schemas';
import SubCategoryCard from './sub-category-card';
import { modifyString } from '@src/utils/methods';

export default function GroupInfo() {
  const { selectedGroup, allGroups } = useContext(GroupsContext);
  const [isEditingAllowed, setIsEditingAllowed] = useState(false);

  useEffect(() => {
    if (!selectedGroup) setIsEditingAllowed(false);
  }, [selectedGroup]);

  return allGroups && (
    <Box sx={{
      display: 'flex', flexDirection: 'column', height: '100%', width: '100%',
    }}
    >
      <GroupDetailsSection {...{ isEditingAllowed, setIsEditingAllowed }} />
      {selectedGroup && <Divider />}
      {selectedGroup && <SubcategoriesSection {...{ selectedGroup }} />}
    </Box>
  );
}

const GroupDetailsSection = ({ isEditingAllowed, setIsEditingAllowed }) => {
  const [loading, setLoading] = useState(false);
  const {
    selectedGroup, setSelectedGroup, allGroups, setAllGroups, categoryTypeOptions,
  } = useContext(GroupsContext);
  const { storeId } = useContext(AppContext);

  const isEditable = () => {
    if (!selectedGroup) return true;
    return isEditingAllowed;
  };

  const saveParams = useMemo(() => ({
    storeId, selectedGroup, setSelectedGroup, setAllGroups, setLoading, setIsEditingAllowed,
  }), [selectedGroup, setAllGroups, setIsEditingAllowed, setSelectedGroup, storeId]);

  const deleteParams = useMemo(() => ({
    storeId, selectedGroup, setSelectedGroup, allGroups, setAllGroups, setLoading,
  }), [allGroups, selectedGroup, setAllGroups, setSelectedGroup, storeId]);

  const formik = useFormik({
    initialValues: initializeGroupFields(selectedGroup),
    validationSchema: groupSchema,
    onSubmit: (values) => handleGroupSave({
      group: {
        ...values,
        categories: selectedGroup?.categories,
      },
      ...saveParams,
    }),
    validate: () => ({}),
    enableReinitialze: true,
  });

  const handleDiscard = () => {
    formik.setValues(initializeGroupFields(selectedGroup));
    setIsEditingAllowed(false);
  };

  useEffect(() => {
    formik.setValues(initializeGroupFields(selectedGroup));
    formik.setTouched(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup]);

  return (
    <Box sx={{ padding: 2, width: '100%' }}>
      {!isEditable()
        && (
          <Stack sx={{ paddingY: 2 }} spacing={2} direction="row" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
                {`${modifyString(formik.values.groupId?.toLowerCase() || '', 50)} `}
                <Chip variant="outlined" label={formik.values.sortOrder || 0} />
              </Typography>
              {formik.values.color && <Typography variant="subtitle1">{modifyString(formik.values.color, 50)}</Typography>}
              {formik.values.categoryType && <Typography variant="subtitle1">{modifyString(formik.values.categoryType, 50)}</Typography>}
              <Typography variant="subtitle2">{modifyString(formik.values.description, 70)}</Typography>
            </Box>
            <Stack direction="row" spacing={2}>
              <Edit onClick={() => setIsEditingAllowed(true)} sx={{ cursor: 'pointer', fontSize: 24, color: '#356ffb' }} />
              {!loading ? (
                <Delete onClick={() => handleGroupDelete(deleteParams)}
                  sx={{ cursor: 'pointer', fontSize: 24, color: '#D14C3C' }}
                />
              )
                : <Loading size={26} color={colors.secondary} />}
            </Stack>
          </Stack>
        )}
      {isEditable()
        && (
          <form onSubmit={formik.handleSubmit}>
            <Stack sx={{ py: 2 }} direction="row" justifyContent="space-between">
              <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
                {selectedGroup ? formik.values.groupId?.toLowerCase() || '' : 'Create New Group'}
              </Typography>
              <Stack direction="row" spacing={1}>
                <Button variant="contained" color="error" onClick={handleDiscard}>Discard</Button>
                <Button variant="contained" color="primary" onClick={() => (!loading ? formik.handleSubmit() : null)}>
                  {!loading ? 'Save' : <Loading size={24} color="white" />}
                </Button>
              </Stack>
            </Stack>
            <Stack sx={{ py: 2 }} direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
                <Stack width="100%" direction="row" justifyContent="space-between" spacing={1}>
                  <TextField sx={{ flex: 1 }} InputProps={{ readOnly: !!selectedGroup }} required name="groupId" label="Group Id" type="text" value={formik.values.groupId} onChange={formik.handleChange} error={formik.touched.groupId && Boolean(formik.errors.groupId)} helperText={formik.touched.groupId && formik.errors.groupId} />
                  <ComboBox sx={{ flex: 1 }} options={categoryTypeOptions} name="categoryType" label="Category Type" type="text" value={formik.values.categoryType} onChange={formik.handleChange} error={formik.touched.categoryType && Boolean(formik.errors.categoryType)} helperText={formik.touched.categoryType && formik.errors.categoryType} />
                </Stack>
                <Stack width="100%" direction="row" justifyContent="space-between" spacing={1}>
                  <TextField sx={{ flex: 1 }} name="color" label="Color" type="text" value={formik.values.color} onChange={formik.handleChange} error={formik.touched.color && Boolean(formik.errors.color)} helperText={formik.touched.color && formik.errors.color} />
                  <TextField sx={{ flex: 1 }} name="sortOrder" label="Sort Order" type="text" value={formik.values.sortOrder} onChange={formik.handleChange} error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)} helperText={formik.touched.sortOrder && formik.errors.sortOrder} />
                </Stack>
                <TextField sx={{ flex: 1 }} multiline rows={4} required name="description" label="Description" type="text" value={formik.values.description} onChange={formik.handleChange} error={formik.touched.description && Boolean(formik.errors.description)} helperText={formik.touched.description && formik.errors.description} />
              </Stack>
            </Stack>
          </form>
        )}
    </Box>
  );
};

const SubcategoriesSection = () => {
  const { selectedGroup, categories, setCategories } = useContext(GroupsContext);
  const [isNewCategoryAdded, setIsNewCategoryAdded] = useState(false);
  const { storeId } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const [subCategories, setSubCategories] = useState(selectedGroup?.categories || []);

  useEffect(() => {
    setSubCategories(selectedGroup?.categories || []);
  }, [selectedGroup]);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" padding={2}>
        <Typography>
          Subcategories(
          {subCategories.length || 0}
          )
        </Typography>
        {!loading
          ? (
            <Button sx={{ height: '100%' }}
              onClick={() => showAddNewSubcategoryDialog({
                storeId, categories, setCategories, setIsNewCategoryAdded, setLoading,
              })}
            >
              <Add />
              <Box>ADD NEW SUBCATEGORY</Box>
            </Button>
          )
          : <Loading size={36} />}
      </Stack>
      <Divider sx={{ mb: 2 }} />
      <NewSubCategoryDialog {...{ isNewCategoryAdded, setIsNewCategoryAdded }} />
      {/* <Stack spacing={2} sx={{ overflowY: 'auto', px: 2, pb: 2 }}> */}
      <Grid container spacing={2} sx={{ overflowY: 'auto', p: 2 }}>
        {subCategories.map((subCategory, index) => (
          <Grid item
            key={index}
            xs={12}
            sm={expandedIndex === index ? 12 : 6}
            lg={expandedIndex === index ? 12 : 4}
          >
            <Box sx={{ boxShadow: 1, borderRadius: 1, transition: 'width 0.3s ease-in-out' }}>
              <SubCategoryCard {...{
                subCategory, setIsNewCategoryAdded, index, expandedIndex, setExpandedIndex,
              }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      {/* </Stack> */}
    </>
  );
};
