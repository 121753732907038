import { useContext, Component, useEffect } from 'react';
import AtAGlanceSalesReport from './atAGlanceSalesReport';
import { AppContext } from '../../context/app-context';
import {
  Box, Card, CircularProgress, Stack,
} from '@mui/material';
import { getSalesSummary } from '@src/api';
import SalesDetailsReport from './salesDetailsReport';
import { DateFilterWidget, getFromDateFromFilter, getToDateFromFilter } from './dateFilter';
import dayjs from 'dayjs';

export default function Reports() {
  const { storeId, allStoresDetails } = useContext(AppContext);
  const storeDetails = allStoresDetails.find(x => x.storeId === storeId) ?? {
    storeId,
    storeName: storeId,
    locale: 'en_US',
    timeZone: 'Asia/Muscat'
  }

  useEffect(() => {
  }, [storeId]);

  return (
    <SalesReport storeId={storeId} storeDetails={storeDetails} />
  );
}

class SalesReport extends Component {
  constructor(props) {
    super(props);
    const { storeId, storeDetails } = this.props;
    this.state = {
      storeId,
      reportData: null,
      status: 'LOADING',
      storeDetails
    };
    this.loadReports = this.loadReports.bind(this);
    this.currentFilter = {};
    dayjs.tz.setDefault(storeDetails.timeZone);
  }

  componentDidMount() {
    this.loadReports(getFromDateFromFilter('TODAY', this.state.storeDetails.timeZone),
      getToDateFromFilter('TODAY', this.state.storeDetails.timeZone));
  }

  componentDidUpdate(prevProps) {
    if (this.props.storeId !== prevProps.storeId) {
      this.loadReports(this.currentFilter.fromDate, this.currentFilter.toDate);
    }
  }

  loadReports(fromDate, toDate, filterId) {
    const { storeId } = this.props;
    this.setState({
      reportData: null,
      status: 'LOADING',
    });
    this.currentFilter = {
      filterId,
      fromDate,
      toDate,
    };
    getSalesSummary(fromDate, toDate, storeId).then((data) => {
      console.log('reports Fetched', storeId);
      this.setState({
        reportData: data,
        status: 'LOADED',
      });
    }).catch((err) => {
      console.log('Error while fetching report data', err.message);
      this.setState({
        reportData: null,
        storeId,
        status: 'ERROR',
      });
    });
  }

  render() {
    const { reportData, status } = this.state;
    const { storeId, storeDetails } = this.props;
    return (
      <Box
        sx={{
          backgroundColor: '#e2e2e2',
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Stack direction="column" sx={{ height: '100%', paddingX: 1, paddingY: 0 }} spacing={1}>
          <Box>
            <Card>
              <DateFilterWidget
                storeId={storeId}
                fetchReports={this.loadReports}
                storeDetails={storeDetails}
              />
            </Card>
          </Box>
          {status === 'LOADING' ?
            (<Stack direction="row" sx={{ height: '100%', width: '100%', paddingX: 2, paddingY: 1 }} spacing={2} justifyContent="center">
              <CircularProgress />
            </Stack>) :
            status === 'ERROR' ?
              (<Stack direction="row" justifyContent="center">Opps! We encountered an issue while fetching the report data!!</Stack >)
              :
              reportData?.gross_total ?
                (<>
                  <Stack direction="row" sx={{ width: '100%', paddingX: 2, paddingY: 1 }} spacing={2} justifyContent="center">
                    <AtAGlanceSalesReport
                      reportData={reportData}
                      fromDate={this.currentFilter.fromDate}
                      toDate={this.currentFilter.toDate}
                      status={status} style={{ display: 'inline-block' }} />
                  </Stack>
                  <Stack direction="row" sx={{ width: '100%', paddingX: 2, paddingY: 1 }} spacing={2} justifyContent="center">
                    <SalesDetailsReport
                      reportData={reportData}
                      fromDate={this.currentFilter.fromDate}
                      toDate={this.currentFilter.toDate}
                      status={status}
                      style={{ display: 'inline-block' }}
                      storeDetails={storeDetails}
                    />
                  </Stack>
                </>)
                : (<Box>No Data Available</Box>)
          }
        </Stack>
      </Box>
    );
  }
}
