import React, { useContext } from 'react';
import { Card, Typography } from '@mui/material';
import { GroupsContext } from '../../context/groups-context';
import { modifyString } from '@src/utils/methods';

export default function GroupCard({ group }) {
  // const [selected, setSelected] = useState(false);
  const { selectedGroup, setSelectedGroup } = useContext(GroupsContext);

  const handleCardClick = () => {
    setSelectedGroup(group);
  };

  return (
    <Card sx={{
      padding: '8px 10px',
      borderLeft: selectedGroup?.groupId === group?.groupId ? '6px solid #356ffb' : '6px solid transparent',
      backgroundColor: selectedGroup?.groupId === group?.groupId ? '#f2f2ff' : '#fcfcfc',
      borderBottom: '1px #e2e2e2 solid',
      cursor: 'pointer',
      boxShadow: 0,
      borderRadius: selectedGroup?.groupId === group?.groupId ? 1 : 0,
    }}
      onClick={() => handleCardClick()}
    >
      <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>{modifyString(group?.groupId?.toLowerCase(), 70)}</Typography>
    </Card>
  );
}
