import {
  getProductsList, modifyModifier, modifyProduct,
  // modifyProduct,
} from '@src/api/productHandlers';
import { AppConstants, notify } from '../../config/app-config';

export const intializeProductsPage = async ({ ...props }) => {
  try {
    console.log('Initializing products page');
    props.setLoading(true);
    const tmpPage = props.pages.find((item) => item.pageNo === props.currentPage - 1);
    console.log('Pages:', props.pages);
    console.log('Current Page:', props.currentPage);
    console.log('Temporary Page:', tmpPage);

    const data = await getProductsList(props.storeId, tmpPage.lastEvaluatedKey);
    props.setPages((prev) => {
      const page = prev.find((item) => item.pageNo === props.currentPage);
      if (page) {
        const updatedPage = {
          ...page,
          lastEvaluatedKey: data.lastEvaluatedKey,
        };
        return prev.map((item) => (item.pageNo === props.currentPage ? updatedPage : item));
      }
      const pageNo = prev.length;
      return ([...prev, {
        pageNo,
        lastEvaluatedKey: data.lastEvaluatedKey,
      }]);
    });
    props.setAllProducts(data.items);
    props.setProducts(data.items);
    props.setSelectedProduct(data.items[0]);
  } catch (err) {
    console.log('Error recieved');
    notify(AppConstants.ERROR, err.message);
  } finally {
    props.setLoading(false);
  }
};

export function sortProducts(props) {
  const products = [...props.products];
  if (props.param === AppConstants.PRICE) {
    products.sort(
      (a, b) => (a.salePrice - b.salePrice !== 0
        ? a.salePrice - b.salePrice
        : a.sku.localeCompare(b.sku)),
    );
  }
  if (props.param === AppConstants.BRAND) { products.sort((a, b) => a.brand.localeCompare(b.brand)); }
  if (props.param === AppConstants.CATEGORY) {
    products.sort((a, b) => a.categories[0].localeCompare(b.categories[0]));
  }
  props.setProducts(products);
}

// export const sortProducts = (props) => {

// }

export const initializeProductFields = (selectedProduct) => ({
  name: selectedProduct?.name || '',
  sku: selectedProduct?.sku || '',
  // color: selectedProduct?.color || '',
  // size: selectedProduct?.size || '',
  // uom: selectedProduct?.uom || '',
  brand: selectedProduct?.brand || '',
  listPrice: selectedProduct?.listPrice || '',
  kitchenType: selectedProduct?.kitchenType || [],
  salePrice: selectedProduct?.salePrice || '',
  description: selectedProduct?.description || '',
  vatGroup: selectedProduct?.vatGroup || '',
  categories: selectedProduct?.categories || [],
});

export const refereshModifiersFormFields = (props) => {
  props.setName(props.selectedProduct.name || '');
  props.setModiferId(props.selectedProduct.modifer || '');
  props.setDescription(props.selectedProduct.description || '');
};

export const saveProduct = async (props) => {
  try {
    console.log('Save product props : ', props);
    props.setLoading(true);
    await modifyProduct(AppConstants.UPDATE, {
      ...props.product,
      salePrice: Number(props.product.salePrice),
      listPrice: Number(props.product.listPrice),
      storeId: props.storeId,
    });
    props.setAllProducts((prev) => prev.map(
      (product) => (product.sku === props.product.sku ? props.product : product),
    ));
    props.setSelectedProduct(props.product);
    notify(AppConstants.SUCCESS, 'Product updated successfully');
  } catch (err) {
    notify(AppConstants.ERROR, err.message);
  } finally {
    props.setLoading(false);
  }
};

export const saveNewProduct = async (props) => {
  try {
    props.setLoading(true);
    await modifyProduct(AppConstants.ADD, {
      ...props.product,
      storeId: props.storeId,
      salePrice: Number(props.product.salePrice),
      listPrice: Number(props.product.listPrice),
    });
    props.setAllProducts((prev) => [props.product, ...prev]);
    props.handleClose();
    props.setSelectedProduct(props.product);
    notify(AppConstants.SUCCESS, 'Product added successfully');
  } catch (err) {
    notify(AppConstants.ERROR, err.message);
  } finally {
    props.setLoading(false);
  }
};

export const deleteProduct = async (props) => {
  try {
    props.setLoading(true);
    // ---api call --------------------------------
    const res = await modifyProduct(AppConstants.DELETE, {
      storeId: props.storeId,
      sku: props.selectedProduct.sku,
    });
    console.log('res : ', res);
    props.setSelectedProduct((prev) => {
      const index = props.allProducts.indexOf(prev);
      if (index + 1 < props.allProducts.length) return props.allProducts[index + 1];
      if (index - 1 >= 0 && index - 1 < props.allProducts.length) return props.allProducts[index - 1];
      return props.allProducts[0];
    });
    props.setAllProducts((prev) => prev.filter((product) => product.sku !== props.selectedProduct.sku));
    notify(AppConstants.SUCCESS, 'Product deleted successfully');
  } catch (err) {
    notify(AppConstants.ERROR, err.message);
  } finally {
    props.setLoading(false);
  }
};

export const saveModifier = async (props) => {
  try {
    props.setLoading(true);
    await modifyModifier(AppConstants.UPDATE, {
      ...props.modifier,
      price: Number(props.modifier.price),
      storeId: props.storeId,
      parentSku: props.selectedProduct.sku,
    });
    const modifiers = props.selectedProduct.modifiers.map(
      (item) => (item.modifierId === props.modifierId ? props.modifier : item),
    );
    const newProduct = { ...props.selectedProduct, modifiers };
    props.setSelectedProduct(newProduct);
    props.setAllProducts((prev) => prev.map(
      (product) => (product.sku === props.selectedProduct.sku ? newProduct : product),
    ));
    props.setIsEditable(false);
    notify(AppConstants.SUCCESS, 'Modifier updated successfully');
  } catch (err) {
    console.log('Caught error');
    notify(AppConstants.ERROR, err.message);
  } finally {
    props.setLoading(false);
  }
};

export const saveNewModifier = async (props) => {
  try {
    props.setLoading(true);
    const { modifierId } = await modifyModifier(AppConstants.ADD, {
      ...props.modifier,
      price: Number(props.modifier.price),
      storeId: props.storeId,
      parentSku: props.selectedProduct.sku,
    });
    const modifier = {
      ...props.modifier,
      modifierId,
    };
    const modifiers = Array.isArray(props.selectedProduct.modifiers)
      ? [modifier, ...props.selectedProduct.modifiers] : [modifier];
    const newProduct = { ...props.selectedProduct, modifiers };
    props.setModifierId(modifierId);
    props.setSelectedProduct(newProduct);
    props.setAllProducts((prev) => prev.map(
      (product) => (product.sku === props.selectedProduct.sku ? newProduct : product),
    ));
    props.handleClose();
    notify(AppConstants.SUCCESS, 'Modifier created successfully');
  } catch (err) {
    notify(AppConstants.ERROR, err.message);
  } finally {
    props.setLoading(false);
  }
};

export const deleteModifier = async (props) => {
  try {
    console.log('Delete modifier called for modifier: ', props.modifier);
    console.log('Product Id: ', props.selectedProduct);
    props.setLoading(true);
    const payload = {
      modifierId: props.modifier.modifierId,
      storeId: props.storeId,
      parentSku: props.selectedProduct.sku,
    };
    console.log(payload);
    // ---api call --------------------------------
    const res = await modifyModifier(AppConstants.DELETE, payload);
    console.log('res : ', res);
    // assuming saved successfully
    const modifiers = props.selectedProduct.modifiers.filter((item) => item.modifierId !== props.modifier.modifierId);
    const newProduct = { ...props.selectedProduct, modifiers };
    console.log('New product ', newProduct);
    props.setSelectedProduct(newProduct);
    props.setAllProducts((prev) => prev.map(
      (product) => (product.sku === props.selectedProduct.sku ? newProduct : product),
    ));
    // props.setSelectedProduct(newProduct);
    notify(AppConstants.SUCCESS, 'Modifier deleted successfully');
  } catch (err) {
    notify(AppConstants.ERROR, err.message);
  } finally {
    props.setLoading(false);
  }
};

export const clearModifier = (props) => {
  props.setName(props.modifier?.name || '');
  props.setPrice(props.modifier?.price || '');
  props.setIsEditable(false);
};
